import { Button } from '@mui/material'
import React from 'react'

const Btn = ({title,func}) => {
    const Style={
        Button : {
            backgroundColor:'primary.Darkgold' ,
            color:'white' ,
            '&:hover' :  {
                backgroundColor:'primary.gold' ,
                color:'white'
            } ,
            // marginLeft:3
        } ,
    }
  return (
            <Button sx={[Style?.Button ]} variant="outlined" onClick={func}>
                {title}
            </Button>
  )
}

export default Btn