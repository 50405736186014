import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useData } from '../../config/Provider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const  DialogAlert = ({open,setopen}) => {

    const handleClose = () => {
        setopen(false);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
            <DialogContent sx={{width:400}}>
            <DialogContentText id="alert-dialog-slide-description">
            الرجاء ملئ الحقول المطلوبة
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} sx={{color:'black'}}>تم</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogAlert