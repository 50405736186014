import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import {  TableHeader} from '../../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { Button, CardMedia, Grid, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { useData } from '../../../config/Provider';
import { Vals } from '../../../config/Vals';
import { useLocalState } from '../../../config/functions';
import HideImageIcon from '@mui/icons-material/HideImage';
import ConstructionIcon from '@mui/icons-material/Construction';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import BarChartIcon from '@mui/icons-material/BarChart';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WidgetsIcon from '@mui/icons-material/Widgets';
const Projects = ({nav}) => {
  const [ savedToken , _ ] = useLocalState("token" );
 
  const {token} = useData()
  const page = () => {
    nav('add-project')
  }

  const visitProject = (id) => {
    nav(`/architecture/${id}`)
  }

  const [search, setSearch] = useState('')
  const [data, setData] = useState([])

  const getData =  async ()  => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+savedToken);
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    await fetch(`${Vals?.API_KEY}get_all_projects`, requestOptions)
      .then(response => response.json())
      .then(result => {
        
        setData(result?.data)

      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
  } , [])
 
  const Header = [
    { title : 'اسم المشروع' } ,
    { title : 'اضافة بند' } ,
    { title : 'مشاهدة' } ,
    // { title : 'حذف' } ,
  ]

  const Card = ({item,nav}) => (
    <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
      <Box sx={{
          display:'flex',
          flexDirection:'column',
          m:1,pt:2,pb:2,pr:2,pl:2,
          borderStyle:'solid',
          borderColor:'gray',
          borderWidth:1,
          borderRadius:1,
          justifyContent:'center',
          alignItems:'center'}}>
        {item?.image ? 
        <CardMedia component={'img'} sx={{width:250,width:250}} image={item?.image} /> : (
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',mt:5,mb:5}}>
            <HideImageIcon  sx={{color:'primary.text',fontSize:100}}/>
          </Box>
        )}
        <Box sx={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',wordBreak:'break-word'}}>
          <Typography sx={{color:'primary.text',mb:1}} fontSize={28}>{item?.project_name} - {item?.project_number}</Typography>
          <Box sx={{height:1.5,backgroundColor:'primary.text',width:'100%',mb:1}} />
        </Box>
          {item?.space === true && (
            <>  
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button onClick={() => nav(`/employees/${item?.id}`)}  sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><PermContactCalendarIcon sx={{color:'primary.text',ml:2,mr:0}}/> الكادر الإداري  </Button>
                <Button onClick={() => nav(`/view-space/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><DesignServicesIcon sx={{color:'primary.text',ml:2,mr:0}}/> مساحة المشروع  </Button>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button onClick={() => nav(`/construction-all/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><HouseSidingIcon sx={{color:'primary.text',ml:2,mr:0}}/> اعمال العظم  </Button>
                <Button onClick={() => nav(`/architecture-all/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><ConstructionIcon sx={{color:'primary.text',ml:2,mr:0}}/> اعمال معمارية </Button>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button  onClick={() => nav(`/plumbing-all/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><PlumbingIcon sx={{color:'primary.text',ml:2,mr:0}}/> اعمال السباكة  </Button>
                <Button  onClick={() => nav(`/electric-all/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><ElectricBoltIcon sx={{color:'primary.text',ml:2,mr:0}}/> اعمال الكهرباء </Button>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button onClick={() => nav(`/specialized-works/${item?.id}/ `)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><BarChartIcon sx={{color:'primary.text',ml:2,mr:0}}/> الاعمال التخصصية </Button>
                <Button onClick={() => nav(`/all-budget/${item?.id}/ `)} sx={{color:'primary.text',borderColor:'primary.red',mr:1}} variant="outlined" ><AttachMoneyIcon sx={{color:'primary.text',ml:2,mr:0}}/> الميزانية </Button>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button onClick={() => nav(`/all-bulletin/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><PointOfSaleIcon sx={{color:'primary.text',ml:2,mr:0}}/> نشرة اسعار المواد و الاجهزة  </Button>
                <Button onClick={() => nav(`/all-equipment/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><WidgetsIcon sx={{color:'primary.text',ml:2,mr:0}}/> تجهيزات الموقع  </Button>
              </Box>

            </>

          )}

          {item?.space === false && (
            <>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button  sx={{color:'primary.text',borderColor:'gray',m:1,backgroundColor:'lightgray'}} variant="outlined" ><PermContactCalendarIcon sx={{color:'primary.text',ml:2,mr:0}}/> الكادر الإداري  </Button>
                <Button onClick={() => nav(`/view-space/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><DesignServicesIcon sx={{color:'primary.text',ml:2,mr:0}}/> مساحة المشروع  </Button>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button  sx={{color:'primary.text',borderColor:'gray',m:1,backgroundColor:'lightgray'}} variant="outlined" ><HouseSidingIcon sx={{color:'primary.text',ml:2,mr:0}}/> اعمال العظم  </Button>
                <Button sx={{color:'primary.text',borderColor:'gray',m:1,backgroundColor:'lightgray'}} variant="outlined" ><ConstructionIcon sx={{color:'primary.text',ml:2,mr:0}}/> اعمال معمارية </Button>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button sx={{color:'primary.text',borderColor:'gray',m:1,backgroundColor:'lightgray'}} variant="outlined" ><PlumbingIcon sx={{color:'primary.text',ml:2,mr:0}}/> اعمال السباكة  </Button>
                <Button sx={{color:'primary.text',borderColor:'gray',m:1,backgroundColor:'lightgray'}} variant="outlined" ><ElectricBoltIcon sx={{color:'primary.text',ml:2,mr:0}}/> اعمال الكهرباء </Button>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button sx={{color:'primary.text',borderColor:'gray',m:1,backgroundColor:'lightgray'}} variant="outlined" ><BarChartIcon sx={{color:'primary.text',ml:2,mr:0}}/> الاعمال التخصصية </Button>
                <Button sx={{color:'primary.text',borderColor:'gray',mr:1,backgroundColor:'lightgray'}} variant="outlined" ><AttachMoneyIcon sx={{color:'primary.text',ml:2,mr:0}}/> الميزانية </Button>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',wordBreak:'break-word'}}>
                <Button onClick={() => nav(`/all-bulletin/${item?.id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" ><PointOfSaleIcon sx={{color:'primary.text',ml:2,mr:0}}/> نشرة اسعار المواد و الاجهزة  </Button>
                <Button  sx={{color:'primary.text',borderColor:'gray',m:1 , backgroundColor:'lightgray'}} variant="outlined" ><WidgetsIcon sx={{color:'primary.text',ml:2,mr:0}}/> تجهيزات الموقع  </Button>
              </Box>
            </>
          )}

      </Box>
    </Grid>
  )

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:'المشاريع' , buttonTitle : 'اضافة جديد' , buttonIcon : <AddIcon /> , page : page }} />
      <Grid container>
        {data?.map((item,key) => <Card key={key} {...{item,nav}} /> )}
      </Grid>
    </Box>
  )
}

export default Projects