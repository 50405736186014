import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { DialogAlert, TableHeader, TextInput } from '../../../components'
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Vals } from '../../../config/Vals';
import Btn from '../../../components/Btn/Btn';
import { useParams } from 'react-router-dom';
import { useLocalState } from '../../../config/functions';
import BlurOnTwoToneIcon from '@mui/icons-material/BlurOnTwoTone';

const AddBulletin = ({nav}) => {
    
  const [ constInputs , setConstInputs ] = useState([])
  const [constInputsVal, setConstInputsVal] = useState({});
  const [ name , setName ] = useState('')
  const [ duration , setDuration ] = useState()
  const [ number , setNumber ] = useState()
  const [ salary , setSalary ] = useState()
  const [ total , setTotal ] = useState()
  const [ savedToken , _ ] = useLocalState("token" );
  const [open , setopen] = useState(false)
  
  const {id} = useParams()

  const Style = {
    SearchInput : {
      input : {color:'primary.text' ,p:2,fontSize:25 },
      'textarea' : {color:'primary.text' , },
      borderWidth:1,
      borderColor:'red',
      backgroundColor:'primary.main' , 
      '&:hover' : {  backgroundColor : 'primary.main'},
      color : 'white' ,
      width:'100%' ,
    }
  }

  const c = []

  const checkRequired = async (arr) => {
    var value = true
    arr?.map((item,key) => {
      for (var i = 0 ; i < constInputs?.length ; i++) {
        if (
          !Object(item)['name_'+i]?.text.trim() ||
          !Object(item)['unit_'+i]?.text.trim() ||
          !Object(item)['statement_unit_'+i]?.text.trim() ||
          parseFloat(Object(item)['quantity_'+i]?.text) == 0 ||
          parseFloat(Object(item)['cost_price_'+i]?.text) == 0 ||
          parseFloat(Object(item)['price_'+i]?.text) == 0  ||
          !Object(item)['note_'+i]?.text.trim()
        ) {
          value = false
        }
      }
    })
    return value
  }

  const page = async () => {

    const check = await checkRequired([constInputsVal])

    if (!check) {
      setopen(true)
      return false
    }
 
    [constInputsVal]?.map((item,key) => {
        for (var i = 0 ; i < constInputs?.length ; i++) {
          c.push({
            "main_statement_name":Object(item)['name_'+i]?.text,
            "unit":Object(item)['unit_'+i]?.text,
            "statement_unit":Object(item)['statement_unit_'+i]?.text,
            "quantity":parseFloat(Object(item)['quantity_'+i]?.text),
            "cost_price":parseFloat(Object(item)['cost_price_'+i]?.text),
            "price":parseFloat(Object(item)['price_'+i]?.text),
            "note":Object(item)['note_'+i]?.text,
          });
        }
      });
   

    const data = JSON.stringify({
        "project_id":id,
        "bands":c
    })
 
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+savedToken);
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
      redirect: 'follow'
    };
    
    fetch(`${Vals?.API_KEY}add_price_bulletin`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.status) {
          nav(`/all-bulletin/${id}`)
        } else {
          console.log(result?.data?.message)
        }
      })
      .catch(error => console.log('error', error));
  }

    const handleInputChangeConst = (event) => {
        const newFormData = {};
        const { name, value } = event.target;
        newFormData[name] = {text : value};
        setConstInputsVal({ ...constInputsVal, ...newFormData });
    };

  const deleteConst = (num) => {
    const filteredItems = constInputs.filter((item, index) => index !== num);
    setConstInputs(filteredItems)
   }

   const AddConstractor = () => {
    setConstInputs([...constInputs , {title : `مقاول ` , key : constInputs?.length+1 }] )
  }

  useEffect(() => {
    AddConstractor()
  }, [])
  
 
 
  return (
    <Box p={2}>
      <TableHeader {...{ title:'اضافة نشرة اسعار' , buttonTitle : 'اضافة' , buttonIcon : <AddIcon /> , page : page }} />
      <DialogAlert {...{open:open,setopen:setopen}} /> 
      <Box sx={{justifyContent:'flex-start',display:'flex',alignItems:'flex-start' }}>
        <Box sx={{ width:{sx:'100%',md:'50%'} ,  }} p={2}>
          
          { constInputs?.map((item,key) => (
              <Box sx={{mb:2,pt:2,borderWidth:0,borderTopWidth:1,borderStyle:'solid',borderColor:'white'}} key={key}>
                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1}> اسم البند 
            <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /></Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`name_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`name_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1}> الوحدة 
            <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /></Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`unit_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`unit_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1}>  وحدة البند المدروس 
            <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /> </Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`statement_unit_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`statement_unit_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>

                

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1}> الكمية 
            <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /></Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`quantity_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`quantity_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1}> سعر الوحدة 
            <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /></Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`cost_price_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`cost_price_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1}> التسعيرة 
            <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /></Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`price_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`price_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1}> ملاحظة 
            <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /></Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`note_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`note_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>
 
                {constInputs?.length > 1 && key !=0 && (
                    <Btn {...{title:'ازالة',func:() => deleteConst(key) }} />
                )}
              </Box>
          
          ) )}

            <Btn {...{title:'اضافة مزيد' , func:AddConstractor}} />

        </Box>

        <Box sx={{ width:{sx:'100%',md:'25%'} , }} p={2}>

        </Box>

        <Box sx={{ width:{sx:'100%',md:'25%'} , }} p={2}>
        
        </Box>

      </Box>

    </Box>
  )
}

export default AddBulletin