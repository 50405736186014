export const Style = {
    
    Container : {
        display:'flex',
        justifyContent:'center' ,
        alignItems:'center' ,
        padding : 0 ,
        marginBottom : 3,
        pr:3,pl:3
    } ,

    InnerContainer : {
        display:'flex',
    } ,

    Button : {
        backgroundColor:'primary.Darkgold' ,
        color:'white' ,
        '&:hover' :  {
            backgroundColor:'primary.gold' ,
            color:'white'
        } ,
        marginLeft:3
    } ,
 
}