import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback } from 'react'
import { TableHeader, TextInput , Btn, DialogAlert} from '../../../components'
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Vals } from '../../../config/Vals';
import { useParams } from 'react-router-dom';
import { useLocalState } from '../../../config/functions';
import BlurOnTwoToneIcon from '@mui/icons-material/BlurOnTwoTone';
import { useData } from '../../../config/Provider';

const AddMainArchitecture = ({nav}) => {
  
  const [ title , setTitle ] = useState('')
  const [ mattsInputs , setMattsInputs ] = useState([])
  const [ constInputs , setConstInputs ] = useState([])
  const [constInputsVal, setConstInputsVal] = useState({});
  const [mattsInputsVal, setMattsInputsVal] = useState({});
  const [ savedToken , _ ] = useLocalState("token" );
  const [open , setopen] = useState(false)
  const {id} = useParams()

  const Style = {
    SearchInput : {
      input : {color:'primary.text' , },
      'textarea' : {color:'primary.text' , },
      borderWidth:1,
      borderColor:'red',
      backgroundColor:'primary.main' , 
      '&:hover' : {  backgroundColor : 'primary.main'},
      color : 'white' ,
      width:'100%' ,
    }
  }

  const c = []
  const m = []

  const checkRequired = async (arr) => {
    var val = false
    if (arr?.length > 0) {
      arr?.map((item) => {
        for (const key in item) {
            val = (item[key].text).trim() ? true : false
        }
      })
    }
    return val
  }

  const page = async () => {

    if (!title.trim()) {
      setopen(true)
      return false
    }

    const checkConst = await checkRequired([constInputsVal])
    const checkMats = await checkRequired([mattsInputsVal])
    
    if (constInputs?.length > 0 && !checkConst) {
      setopen(true)
      return false
    }

    if (mattsInputs?.length > 0 && !checkMats) {
      setopen(true)
      return false
    }

    [constInputsVal]?.map((item) => {
      for (const key in item) {
        c.push(item[key].text);
      }
    });
    

    [mattsInputsVal]?.map((item) => {
      for (const key in item) {
        m.push(item[key].text);
      }
    });

    const data = JSON.stringify({
      "project_id":id,
      "bands":[
          {
            "main_architecture_name": title ,
            "material_names": m ,
            "contractor_names": c
          },
      ]
    })

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+savedToken);
    myHeaders.append("Content-Type", "application/json");
   
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
      redirect: 'follow'
    };
    
    fetch(`${Vals?.API_KEY}add_main_architecture`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.status) {
          nav(`/architecture-all/${id}`)
        } else {
          console.log(result?.message)
        }
      })
      .catch(error => console.log('error', error));
  }

  const AddMatt = () => {
    setMattsInputs([...mattsInputs , {title : `مادة `}] )
  }

  const AddConstractor = () => {
    setConstInputs([...constInputs , {title : `مقاول `}] )
  }

  const deleteMatt = (num) => {
   const filteredItems = mattsInputs.filter((item, index) => index !== num);
   setMattsInputs(filteredItems)
  }

  const deleteConst = (num) => {
    const filteredItems = constInputs.filter((item, index) => index !== num);
    setConstInputs(filteredItems)
   }
  const handleInputChangeMatt = (event) => {
    const newFormData = {};
    const { name, value } = event.target;
    newFormData[name] = {text : value};
    setMattsInputsVal({ ...mattsInputsVal, ...newFormData });
  };

  const handleInputChangeConst = (event) => {
    const newFormData = {};
    const { name, value } = event.target;
    newFormData[name] = {text : value};
    setConstInputsVal({ ...constInputsVal, ...newFormData });
  };
 
  return (
    <Box p={2}>
      <DialogAlert {...{open:open,setopen:setopen}} /> 
      <TableHeader {...{ title:'اضافة بند' , buttonTitle : 'اضافة' , buttonIcon : <AddIcon /> , page : page }} />
      <Box sx={{justifyContent:'flex-start',display:'flex',alignItems:'flex-start' }}>
        <Box sx={{ width:{sx:'100%',md:'50%'} , }} p={2}>
          <Box sx={{mb:2}}>
            <Typography color='primary.text' mb={1}> اسم البند 
            <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /> 
            </Typography>
            <TextInput {...{set:setTitle,value:title }}/>
          </Box>
    
        </Box>

        <Box sx={{ width:{sx:'100%',md:'25%'} , }} p={2}>

          <Box sx={{justifyContent:'flex-start',display:'flex',alignItems:'center' , flexDirection:'column'}}>
            
            <Box sx={{mb:2}}>
                <Btn {...{title:'اضافة مقاول' , func:AddConstractor}} />
            </Box>

            {constInputs?.map((item,key) => (

                <Box sx={{mb:2,}} key={key}>
                  <Typography color='primary.text' mb={1}> {item?.title} 
                    <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /> 
                  </Typography>
                  <Box sx={{display:'flex',flexDirection:'row'}}>
                        <InputBase type='text' 
                          value={constInputsVal[`Const_${key}`]?.text} 
                          onChange={handleInputChangeConst} 
                          name={`Const_${key}`} 
                          sx={Style?.SearchInput} 
                          variant="outlined"
                          autoComplete='off'
                        />
            
                    <Btn {...{title:'ازالة',func:() => deleteConst(key) }} />
                  </Box>
                </Box>
            ) )}
        
          </Box>


        </Box>

        <Box sx={{ width:{sx:'100%',md:'25%'} , }} p={2}>
          
          <Box sx={{mb:2}}>
              <Btn {...{title:'اضافة مادة' , func:AddMatt }} />
          </Box>

          { mattsInputs?.map((item,key) => (
          
            <Box sx={{mb:2,}} key={key}>
              <Typography color='primary.text' mb={1}> {item?.title} 
              <BlurOnTwoToneIcon sx={{fontSize:15,color:'red'}} /> 
              </Typography>
              <Box sx={{display:'flex',flexDirection:'row'}}>
                <InputBase type='text' 
                      value={mattsInputsVal[`Matt_${key}`]?.text} 
                      onChange={handleInputChangeMatt} 
                      name={`Matt_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                <Btn {...{title:'ازالة',func:() => deleteMatt(key) }} />
              </Box>
            </Box>
          
          ))}

        </Box>

      </Box>

    </Box>
  )
}

export default AddMainArchitecture