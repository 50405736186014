import { ThemeProvider } from "@mui/material";
import React ,{ createContext, useState, useContext, useEffect } from "react";
import { Light , Dark } from "../style/ThemeStyle";
import { useLocalState } from "./functions";
import { DialogAlert } from "../components";
export const Prv = createContext();

export const useData = () => {
    return useContext(Prv);
}
 
const Provider = ({children}) => {
   
    const [ Islogged , setIslogged ] = useLocalState("Islogged" , false);
    const [ savedToken , setSavedToken ] = useLocalState("token" , null);
    const [showSidebar, setShowSidebar] = useState(true)
    const [token , setToken] = useState('')
    const [logged,setlogged] = useState(false)
    const [load,setLoad] = useState(true)
    const [alerts,setalerts] = useState(false)
    
    useEffect(() => {
        setlogged(!savedToken?false:true)
        setIslogged(!savedToken?false:true)
        setLoad(false)
    } , [])

    if (!load)
    return (
        <Prv.Provider value={{
                showSidebar : showSidebar,
                alerts:alerts,
                setalerts:setalerts , 
                setShowSidebar : setShowSidebar ,
                token , setToken , logged,setlogged
            }} >
                <ThemeProvider theme={Dark}>
                    {children}
                </ThemeProvider>
        </Prv.Provider>
    );

}

export default Provider;