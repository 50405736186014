import { IconButton, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback } from 'react'
import { TableHeader, TextInput } from '../../../components'
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Vals } from '../../../config/Vals';
import { useLocalState } from '../../../config/functions';

const AddProject = ({nav}) => {
  const [ image , setImage ] = useState(null)
  const [ num , setNum ] = useState(null)
  const [ title , setTitle ] = useState('')
  const [ savedToken , _ ] = useLocalState("token" );
  const [ upload , setUpload ] = useState('')
  const Style = {
    SearchInput : {
      input : {color:'primary.text' , },
      'textarea' : {color:'primary.text' , },
      borderWidth:1,
      borderColor:'red',
      backgroundColor:'primary.main' , 
      '&:hover' : {  backgroundColor : 'primary.main'},
      color : 'white' ,
      width:'100%' ,
    }
  }

  const getfile = (e) => {
    setImage(e)
  }

  const handleCreate64 = useCallback(async (e) => {
    const file = e.target.files[0]
    const base64 = await ToBase64(file)
    setImage(base64)
    setUpload(file)
    e.target.value = ''
  },[])

const ToBase64 = (file) => {
  return new Promise( (resolve,reject) => {
    const fileReader = new FileReader()
    if (file) {
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
    }

    fileReader.onerror = (error) => {
      reject(error)
    }

  })
 }

 const deleteImg = () => {
  setImage('')
 }


  const page = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+savedToken);
 
    var raw = "";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+savedToken);
    const formData = new FormData();
    
    formData.append("project_name", title);
    formData.append("image", upload);
    formData.append("project_number", num);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData ,
      redirect: 'follow'
    };
    
    fetch(`${Vals?.API_KEY}add_project`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.status) {
          nav('/projects/')
        } else {
          alert(result?.data?.image[0])
        }
      })
      .catch(error => console.log('error', error));
  }

  return (
    <Box p={2}>
      <TableHeader {...{ title:'اضافة مشروع' , buttonTitle : 'اضافة' , buttonIcon : <AddIcon /> , page : page }} />

      <Box sx={{justifyContent:'flex-start',display:'flex',alignItems:'flex-start' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
          <TextInput {...{set:setTitle,value:title,placeholder:'اسم المشروع'}}/>
          <Box mt={2}>
            <TextInput {...{set:setNum,value:num,placeholder:'رقم المشروع'}}/>
          </Box>
        </Box>



        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>

        <Box sx={{justifyContent:'flex-start',display:'flex',alignItems:'center' , flexDirection:'column'}}>

        { image ? 

            <>
              <img style={{width:270}} src={image} /> 
              <IconButton onClick={deleteImg} color="primary.text" sx={{mt:2,backgroundColor:'primary.Darkgold','&:hover' : { backgroundColor:'primary.Darkgold' }}}  >
                <DeleteIcon />
              </IconButton>        
            </>

            : 

            <>
              <ImageIcon sx={{fontSize:350,color:'primary.main'}} /> 
              <IconButton color="white" sx={{backgroundColor:'primary.Darkgold','&:hover' : { backgroundColor:'primary.Darkgold' }}} aria-label="upload picture" component="label">
                <input  onChange={handleCreate64 }  hidden accept="image/*" type="file" />
                <PhotoCamera />
              </IconButton>
            </>

            }
        </Box>


        </Box>

      </Box>

    </Box>
  )
}

export default AddProject