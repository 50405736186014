import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Vals } from '../../../config/Vals';
import { DataTable, Footer, TableHeader, TextInput } from '../../../components'
import { Style } from './Style'
import { NumC, useLocalState } from '../../../config/functions';

const ViewSpace = ({ nav }) => {

    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
    const { id } = useParams()
    const [savedToken, _] = useLocalState("token");
    var totala = 0
    var totalb = 0
    const getData = async () => {

        var myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer " + savedToken)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders
        };

        await fetch(`${Vals?.API_KEY}get_all_space_statement/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result?.data)
                setLoad(false)
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getData()
    }, [])

    const Header = [
        { title: 'الرقم' },
        { title: 'البند' },
        { title: 'الوحدة' },
        { title: 'الكمية' },
        { title: 'السعر' },
        { title: 'المجموع' },
        { title: 'معامل المساحة' },
        { title: 'المساحات مع المعامل' },
        { title: 'المدة المقترحة عظم' },
        { title: 'المدة المقترحة  تشطيب' },
    ]

    if (!load)
        return (
            <Box sx={Style?.Container}>

                <TableHeader {...{ title: `مساحات المشروع : `, buttonTitle: 'اضافة', page: () => nav(`/add-space/${id}`) }} />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                    <table style={{ width: '100%', backgroundColor: '#fbd4b4' }}>
                        <thead>
                            <tr>
                                <th style={Style?.Col} colSpan={10}> جدول كميات وأسعار مشروع </th>
                            </tr>
                            <tr>
                                <th style={Style?.Col} > رقمه </th>
                                <th style={Style?.Col}  > اسم المشروع </th>
                                <th style={Style?.Col}  >  </th>
                                <th style={Style?.Col} colSpan={2}> قيمة العقد المعتمدة	 </th>
                                <th style={Style?.Col}  > نسبة الأرباح من القيمة المعتمدة </th>
                                <th style={Style?.Col}  > التكاليف دراسة </th>
                                <th style={Style?.Col}  > هامش إداري </th>
                                <th style={Style?.Col} > هامش الربح </th>
                                <th style={Style?.Col}  >  العقد حسب الدراسة  </th>
                            </tr>
                            <tr>
                                <th style={Style?.Col} rowSpan={3}>{data?.project_info[0]?.project_number}</th>
                            </tr>
                            <tr>
                                <th style={Style?.Col} rowSpan={2}>{data?.project_info[0]?.project_name}</th>
                                <th style={Style?.Col} rowSpan={1}>{ }</th>
                                <th style={Style?.Col} rowSpan={1} colSpan={2}>
                                    {NumC(data?.project_stats[0]?.contract_total)}	</th>
                                <th style={Style?.Col} rowSpan={1} colSpan={1}>  {NumC((((data?.project_stats[0]?.contract_total - data?.final_total - data?.total_manage_cost) / data?.project_stats[0]?.contract_total) / 0.8).toFixed(2) * 100)}%	</th>
                                <th style={Style?.Col} rowSpan={1} colSpan={1}> 	</th>
                                <th style={Style?.Col} rowSpan={1} colSpan={1}> 10.00%	</th>
                                <th style={Style?.Col} rowSpan={1} colSpan={1}> 15.00%	</th>
                                <th style={Style?.Col} rowSpan={2} > {NumC(data?.final_total + (data?.total_profit ? data?.total_profit : 0) + (data?.total_manage_cost ? data?.total_manage_cost : 0))}    </th>
                            </tr>
                            <tr>
                                <th style={Style?.Col} rowSpan={1}>مساحة المشروع (م2)</th>
                                <th style={Style?.Col} rowSpan={1} >  {NumC(data?.project_stats[0]?.total_space_with_coefficient)}   </th>
                                <th style={Style?.Col} rowSpan={1} >    مكعبات المشروع   </th>
                                <th style={Style?.Col} rowSpan={1} >     {NumC(data?.construction)}     </th>
                                <th style={Style?.Col} rowSpan={1} >        {NumC(data?.final_total)}  </th>
                                <th style={Style?.Col} rowSpan={1} >      {data?.total_manage_cost ? NumC(data?.total_manage_cost) : 0}          </th>
                                <th style={Style?.Col} rowSpan={1} >       {data?.total_profit ? NumC(data?.total_profit) : 0}     </th>
                            </tr>
                        </thead>

                    </table>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: 40 }}>
                    <table style={Style?.Table}>
                        <thead>
                            <tr style={{ backgroundColor: '#dbe5f1' }}>
                                {Header?.map((v, k) => (<th key={k} style={Style?.Col} > {v.title} </th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.space_statement?.map((v, k) => {
                                totala += v.quantity
                                totalb += v.space_with_coefficient
                                return (
                                    <tr key={v.id} style={{
                                        borderColor: 'black',
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        transition: '0.2s',
                                        backgroundColor: '#d8d8d8',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'primary.main'
                                        },
                                        "& td": {
                                            borderColor: 'black'
                                        }
                                    }}>
                                        <td style={Style?.Col} > {k + 1} </td>
                                        <td style={Style?.Col} > {v.statement} </td>
                                        <td style={Style?.Col} > {v.unit} </td>
                                        <td style={Style?.Col} >  {v.quantity} </td>
                                        <td style={Style?.Col} > {v.price} </td>
                                        <td style={Style?.Col} > {NumC(v.quantity * v.price)} </td>
                                        <td style={Style?.Col} > {v.area_coefficient} </td>
                                        <td style={Style?.Col} > {NumC(v.space_with_coefficient)} </td>
                                        <td style={Style?.Col} > {v.day_process} </td>
                                        <td style={Style?.Col} > {v.day_finishes} </td>
                                    </tr>
                                )
                            })}
                            <tr style={{
                                borderColor: 'black',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                transition: '0.2s',
                                backgroundColor: '#c6efce',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'primary.main'
                                },
                                "& td": {
                                    borderColor: 'black'
                                }
                            }}>
                                <td colSpan={3} style={Style?.Col}> اجمالي المساحات </td>
                                <td colSpan={1} style={Style?.Col}>{NumC(totala)}</td>
                                <td colSpan={3} style={Style?.Col}></td>
                                <td colSpan={1} style={Style?.Col}>{NumC(totalb)}</td>
                                <td colSpan={1} style={Style?.Col}>{NumC(data?.project_stats[0]?.total_days_process)}</td>
                                <td colSpan={1} style={Style?.Col}>{NumC(data?.project_stats[0]?.total_days_finishes)}</td>

                            </tr>
                        </tbody>
                    </table>



                </div>

                <table style={{ width: '100%', marginTop: 50 }}>
                    <tbody>
                        <tr>
                            <th width='50%' style={Style?.Col}>إجمالي قيمة العقد  </th>
                            <th width='50%' style={Style?.Col}> {NumC(data?.project_stats[0]?.contract_total)} </th>
                        </tr>
                        <tr>
                            <th width='50%' style={Style?.Col}>ضريبة القيمة المضافة </th>
                            <th width='50%' style={Style?.Col}> {NumC(data?.project_stats[0]?.value_added_tax)} </th>
                        </tr>
                        <tr>
                            <th width='50%' style={Style?.Col}>إجمالي قيمة العقد مع ضريبة القيمة المضافة </th>
                            <th width='50%' style={Style?.Col}> {NumC(data?.project_stats[0]?.contract_with_tax)} </th>
                        </tr>
                    </tbody>

                </table>
                <Footer {...{ id: id }} />

            </Box>
        )
}

export default ViewSpace