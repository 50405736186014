import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect, useRef } from 'react'
import { TableHeader, TextInput } from '../../../components'
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Vals } from '../../../config/Vals';
import Btn from '../../../components/Btn/Btn';
import { useParams } from 'react-router-dom';
import { useLocalState } from '../../../config/functions';

const AddSpace = ({ nav }) => {
  const t = useRef(true)
  var area_coefficient = 0
  var allTotal = 0
  var oldtax = 0

  const [data, setData] = useState([{
    allTotal: 0,
    total_space_with_coefficient: 0,
    total_quantity: 0,
    day_process: 0,
    day_finishes: 0,
  }])
  const [totala, settotala] = useState(0)

  const getData = async () => {

    var myHeaders = new Headers()
    myHeaders.append("Authorization", "Bearer " + savedToken)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders
    };

    await fetch(`${Vals?.API_KEY}get_all_space_statement/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setData({
          contract_with_tax: parseFloat(result?.data?.project_stats[0]?.contract_with_tax),
          total_space_with_coefficient: parseFloat(result?.data?.project_stats[0]?.total_space_with_coefficient),
          total_quantity: parseFloat(result?.data?.project_stats[0]?.total_quantity),
          total_days_process: parseFloat(result?.data?.project_stats[0]?.total_days_process),
          total_days_finishes: parseFloat(result?.data?.project_stats[0]?.total_days_finishes),
        })

        result?.data?.space_statement?.map((item) => {
          area_coefficient += item?.total_space_with_coefficient
          allTotal += parseFloat(item?.quantity) * parseFloat(item?.price)
          oldtax += parseFloat(item?.value_added_tax)
        })
        settotala(allTotal)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
  }, [])

  const [constInputs, setConstInputs] = useState([])
  const [constInputsVal, setConstInputsVal] = useState({});
  const [tax, setTax] = useState(0)
  const [savedToken, _] = useLocalState("token");

  const { id } = useParams()

  const Style = {
    SearchInput: {
      input: { color: 'primary.text', },
      'textarea': { color: 'primary.text', },
      borderWidth: 1,
      p: 1,
      height: 40,
      borderColor: 'red',
      backgroundColor: 'primary.main',
      '&:hover': { backgroundColor: 'primary.main' },
      color: 'white',
      width: '100%',
    }
  }

  const c = []

  var total_space_with_coefficient = 0
  var total_quantity = 0
  var day_process = 0
  var day_finishes = 0

  const page = () => {

    [constInputsVal]?.map((item, key) => {
      for (var i = 0; i < constInputs?.length; i++) {

        c.push({
          "statement": Object(item)['statement_' + i]?.text,
          "unit": Object(item)['unit_' + i]?.text,
          "quantity": parseFloat(Object(item)['quantity_' + i]?.text),
          "price": parseFloat(Object(item)['price_' + i]?.text),
          "total": parseFloat(Object(item)['price_' + i]?.text) * parseFloat(Object(item)['quantity_' + i]?.text),
          "area_coefficient": parseFloat(Object(item)['area_coefficient_' + i]?.text),
          "space_with_coefficient": parseFloat(Object(item)['area_coefficient_' + i]?.text) * parseFloat(Object(item)['quantity_' + i]?.text),
          "day_process": parseFloat(Object(item)['day_process_' + i]?.text),
          "day_finishes": parseFloat(Object(item)['day_finishes_' + i]?.text)
        })

        allTotal += parseFloat(Object(item)['price_' + i]?.text) * parseFloat(Object(item)['quantity_' + i]?.text)
        total_space_with_coefficient += parseFloat(Object(item)['area_coefficient_' + i]?.text) * parseFloat(Object(item)['quantity_' + i]?.text)
        // total_space_with_coefficient += parseFloat(Object(item)['space_with_coefficient_'+i]?.text)
        total_quantity += parseFloat(Object(item)['quantity_' + i]?.text)
        area_coefficient += parseFloat(Object(item)['area_coefficient_' + i]?.text)
        day_process += parseFloat(Object(item)['day_process_' + i]?.text)
        day_finishes += parseFloat(Object(item)['day_finishes_' + i]?.text)
      }

    });

    // const newTax = parseFloat(tax) + parseFloat(oldtax)
    const totalFinal = allTotal + totala
    const databody =
      JSON.stringify(
        {
          "project_id": id,
          "space_statements": c,
          "total_space_with_coefficient": total_space_with_coefficient,
          "total_quantity": total_quantity,
          "total_days_process": day_process,
          "total_days_finishes": day_finishes,
          "contract_total": totalFinal,
          "value_added_tax": totalFinal * 0.15,
          "contract_with_tax": (totalFinal * 0.15) + totalFinal
        }
      )

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + savedToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: databody,
      redirect: 'follow'
    };

    fetch(`${Vals?.API_KEY}add_space_statement`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.status) {
          nav(`/view-space/${id}`)
        }
        if (result?.message === 'undefined') {
          alert('الرجاء ملئ جميع الحقول')
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 500) {
          alert('الرجاء ملئ جميع الحقول')
        }
        console.log('error', error)
      });
  }

  const handleInputChangeConst = (event) => {
    const newFormData = {};
    const { name, value } = event.target;
    newFormData[name] = { text: value };
    setConstInputsVal({ ...constInputsVal, ...newFormData });
  }

  const deleteConst = (num) => {
    const filteredItems = constInputs.filter((item, index) => index !== num);
    setConstInputs(filteredItems)
  }

  const AddConstractor = () => {
    setConstInputs([...constInputs, { title: `مقاول `, key: constInputs?.length + 1 }])
  }



  useEffect(() => {
    if (t.current === true) {
      t.current = false
      AddConstractor()
    }
  }, [])

  return (
    <Box p={2}>
      <TableHeader {...{ title: 'اضافة بند', buttonTitle: 'اضافة', buttonIcon: <AddIcon />, page: page }} />

      <Box sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-start' }}>
        <Box sx={{ width: { sx: '100%', md: '50%' }, }} p={2}>

          {/* <Box sx={{mb:2}}>
              <Typography color='primary.text' mb={1}> نسبة الضريبة </Typography>
              <TextInput {...{value:tax,set:setTax}} />
          </Box> */}

          {constInputs?.map((item, key) => (
            <Box sx={{ mb: 2, pt: 2, borderWidth: 0, borderTopWidth: 1, borderStyle: 'solid', borderColor: 'white' }} key={key}>
              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> بند </Typography>
                <InputBase type='text'
                  value={constInputsVal[`statement_${key}`]?.text}
                  onChange={handleInputChangeConst}
                  name={`statement_${key}`}
                  sx={Style?.SearchInput}
                  variant="outlined"
                  autoComplete='off'
                  required
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> وحدة </Typography>
                <InputBase type='text'
                  value={constInputsVal[`unit_${key}`]?.text}
                  onChange={handleInputChangeConst}
                  name={`unit_${key}`}
                  sx={Style?.SearchInput}
                  required
                  variant="outlined"
                  autoComplete='off' />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> الكمية </Typography>
                <InputBase type='text'
                  value={constInputsVal[`quantity_${key}`]?.text}
                  onChange={handleInputChangeConst}
                  name={`quantity_${key}`}
                  sx={Style?.SearchInput}
                  required
                  variant="outlined"
                  autoComplete='off' />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> السعر </Typography>
                <InputBase type='text'
                  value={constInputsVal[`price_${key}`]?.text}
                  onChange={handleInputChangeConst}
                  name={`price_${key}`}
                  sx={Style?.SearchInput}
                  required
                  variant="outlined"
                  autoComplete='off' />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> الاجمالي </Typography>
                <Typography color='primary.text' mb={1} fontSize={25}> {isNaN(constInputsVal[`price_${key}`]?.text * constInputsVal[`quantity_${key}`]?.text) ? 0 : (constInputsVal[`price_${key}`]?.text * constInputsVal[`quantity_${key}`]?.text).toFixed(2)} </Typography>

              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> معامل المساحة </Typography>
                <InputBase type='text'
                  value={constInputsVal[`area_coefficient_${key}`]?.text}
                  onChange={handleInputChangeConst}
                  name={`area_coefficient_${key}`}
                  sx={Style?.SearchInput}
                  required
                  variant="outlined"
                  autoComplete='off' />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> مساحات مع المعامل </Typography>
                <Typography color='primary.text' mb={1} fontSize={25}> {isNaN(constInputsVal[`area_coefficient_${key}`]?.text * constInputsVal[`quantity_${key}`]?.text) ? 0 : (constInputsVal[`area_coefficient_${key}`]?.text * constInputsVal[`quantity_${key}`]?.text).toFixed(2)} </Typography>

              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> مدة أعمال العظم  </Typography>
                <InputBase type='text'
                  value={constInputsVal[`day_process_${key}`]?.text}
                  onChange={handleInputChangeConst}
                  name={`day_process_${key}`}
                  required
                  sx={Style?.SearchInput}
                  variant="outlined"
                  autoComplete='off' />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography color='primary.text' mb={1} fontSize={25}> مدة  أعمال  التشطيب </Typography>
                <InputBase type='text'
                  value={constInputsVal[`day_finishes_${key}`]?.text}
                  onChange={handleInputChangeConst}
                  name={`day_finishes_${key}`}
                  sx={Style?.SearchInput}
                  required
                  variant="outlined"
                  autoComplete='off' />
              </Box>
              {constInputs?.length > 1 && key != 0 && (
                <Btn {...{ title: 'ازالة', func: () => deleteConst(key) }} />
              )}
            </Box>

          ))}

          <Btn {...{ title: 'اضافة مزيد', func: AddConstractor }} />
        </Box>




      </Box>

    </Box>
  )
}

export default AddSpace