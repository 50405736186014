import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import Btn from '../Btn/Btn'
import { Style } from './Style'

const TableHeader = ({title , buttonIcon , buttonTitle , page }) => {
    return (
        <Grid container sx={Style?.Container}>

            <Grid sx={[Style?.InnerContainer , { justifyContent:'flex-start' }]} item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant='h4' color='primary.text'>
                    {title}
                </Typography>
            </Grid>
            
            <Grid sx={[Style?.InnerContainer , { justifyContent:'flex-end' } ]} item xs={12} sm={12} md={6} lg={6} xl={6}>
                {buttonTitle && (
                    <Btn {...{title:buttonTitle,func:page}} />
                )}
            </Grid>
            
        </Grid>
    )
}

export default TableHeader