
export const Style = {
    
    Container : {
        paddingRight : 1 ,
        paddingLeft : 1 ,
        paddingTop : 2 ,
        display:'flex',
        flexDirection:'column',
       
    } ,

    ContainerTable : {
        paddingRight : 1 ,
        paddingLeft : 1 ,
        paddingTop : 2 ,
        display:'flex',
        flexDirection:'column', 
        overflow:'scroll',
    },

    Table : { 
        marginTop:15,
        // width:'100%',
        width:3000

    } ,
 
    Row : { 
        borderColor:'white' ,
        borderStyle:'solid',
        borderWidth:1, 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }
    } ,

    MultiRow : {
        borderWidth:0,
        borderLeft : '1px solid #c2c2c2',
        height:'100%',
        color:'white',
        padding:15
    } ,
    MultiRowContainer : {
        display:'flex',
        flexDirection:'row',
        height:'100%',
        width:'100%',
    },
    Col : {
        borderColor:'#c2c2c2' ,
        borderStyle:'solid',
        borderWidth:1, 
        textAlign:'center',
        padding:10
    } ,

    aCol : {
        borderColor:'#c2c2c2' ,
        borderStyle:'solid',
        borderWidth:1, 
        textAlign:'left',
        color:'white',
        padding:10
    } , 

    InnerCol : {
        borderColor:'#c2c2c2' ,
        borderStyle:'solid',
        borderWidth:1, 
        color:'white',
        textAlign:'center',
        padding:0
    } ,

    SearchInput : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderColor:'red',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        color : 'white' ,
        width:'100%' ,
    }

}

