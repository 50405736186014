import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Vals } from '../../../config/Vals';
import { DataTable, Footer, TableHeader } from '../../../components'
import { Style } from './Style'
import { NumC, useLocalState } from '../../../config/functions';
import Btn from '../../../components/Btn/Btn';

const ViewBudget = ({ nav }) => {

    const [data, setData] = useState([])
    const [Totals, setTotals] = useState([])
    const [info, setInfo] = useState([])
    const { id } = useParams()
    const [savedToken, _] = useLocalState("token")

    var totals = []
    const [space, setSpace] = useState(0)

    const getSpace = () => {


        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + savedToken);

        var raw = "";

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${Vals?.API_KEY}get_all_space_statement/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => setSpace(result?.data?.project_stats[0]?.total_space_with_coefficient))
            .catch(error => console.log('error', error));

    }

    useEffect(() => {
        getSpace()
    }, [])

    const getData = async () => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + savedToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        await fetch(`${Vals?.API_KEY}get_all_company_budget/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result?.data)
                setInfo(result?.data?.project_info[0])
            })
            .catch(error => console.log('error', error))
    }

    useEffect(() => {
        getData()
    }, [])

    const types = [
        { title: 'اعمال عظم', value: 'construction', url: `/construction-all/${id}` },
        { title: 'اعمال سباكة', value: 'plumbing', url: `/plumbing-all/${id}` },
        { title: 'اعمال معمارية', value: 'architecture', url: `/architecture-all/${id}` },
        { title: 'اعمال كهرباء', value: 'electrical', url: `/electric-all/${id}` },
    ]

    const TableLoop = () => {

        return (
            [data]?.map((v, key) => {

                return (

                    <>
                        <table style={{ width: '100%', backgroundColor: '#fbd4b4' }} >
                            <thead>
                                <tr>
                                    <th style={{
                                        borderColor: '#c2c2c2',
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        color: 'red',
                                        textAlign: 'center',
                                        padding: 10
                                    }} colSpan={8}>
                                        موازنة مصاريف مشروع  : {info?.project_name}
                                    </th>
                                </tr>
                                <tr>
                                    <th style={Style?.Col}>رقمه</th>
                                    <th style={Style?.Col}>اسم المشروع</th>
                                    <th style={Style?.Col}> العقد حسب الدراسة </th>
                                    <th style={Style?.Col}> قيمة العقد المعتمدة </th>
                                    <th style={Style?.Col}> التكاليف دراسة </th>
                                    <th style={Style?.Col}> التكاليف للموازنة </th>
                                    <th style={Style?.Col}> مساحة المشروع (م2) </th>
                                    <th style={Style?.Col}>  مكعبات المشروع  </th>
                                </tr>

                                <tr>
                                    <td style={Style?.Col}>{info?.project_number}</td>
                                    <td style={Style?.Col}>{info?.project_name}</td>
                                    <td style={Style?.Col}> {NumC(data?.st_price)} </td>
                                    <td style={Style?.Col}>{NumC(data?.space_project?.contract_total)}</td>
                                    <td style={Style?.Col}> {NumC(data?.st_cost_price)} </td>
                                    <td style={Style?.Col}>{NumC(data?.considered_costs)}</td>
                                    <td style={Style?.Col}>{NumC(data?.space_project?.total_space_with_coefficient)}</td>
                                    <td style={Style?.Col}>{NumC(data?.construction)}</td>
                                </tr>
                            </thead>
                        </table>
                        <table style={Style?.Table} key={key}>

                            <tbody>

                                {v?.statement?.map((item, key) => {
                                    var total = 0
                                    var atotal = 0

                                    return (
                                        <React.Fragment key={key}>

                                            <tr>
                                                <th style={Style?.Col}> رقم  </th>
                                                {/* <th style={Style?.Col}>النوع</th> */}
                                                <th style={Style?.Col}>البيان</th>
                                                <th style={Style?.Col}>الوحدة</th>
                                                <th style={Style?.Col}> الكمية </th>
                                                <th style={Style?.Col}> سعر الوحدة تكلفة </th>
                                                <th style={Style?.Col}> التكلفة المدروسة </th>
                                                <th style={Style?.Col}>الكمية الفعلية</th>
                                                <th style={Style?.Col}>سعر الوحدة فعلي</th>
                                                <th style={Style?.Col}> التكلفة الفعلية </th>
                                                <th style={Style?.Col}> الشرح </th>
                                                <th style={Style?.Col}> اضافة </th>
                                            </tr>

                                            <tr style={{
                                                borderColor: 'white',
                                                backgroundColor: '#b6dde8',
                                                borderStyle: 'solid',
                                                borderWidth: 1,
                                                transition: '0.2s',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main'
                                                },
                                                "& td": {
                                                    borderColor: 'primary.main'
                                                }
                                            }}>
                                                <td style={Style?.Col} > {key + 1} </td>
                                                <th style={Style?.Col}>
                                                    <a style={{ textDecoration: 'underline', color: 'red' }} onClick={() => nav(types?.find((i) => i.value == item?.type)?.url)}> {types?.find((i) => i.value == item?.type)?.title} </a>
                                                </th>
                                                {/* <td style={Style?.Col} > {item.main_statement_name}  </td>  */}
                                                <th style={Style?.Col}> {item?.unit} </th>
                                                <th style={Style?.Col}> {item?.quantity}  </th>
                                                <th style={Style?.Col}> {item?.cost_unit_price}  </th>
                                                <th style={Style?.Col}> {item?.cost_price}  </th>
                                                <th style={Style?.Col}> {item?.approved_quantity}  </th>
                                                <th style={Style?.Col}> {item?.approved_unit_price}  </th>
                                                <th style={Style?.Col}> {item?.approved_cost_price}  </th>
                                                <th style={Style?.Col}> {item?.note}  </th>
                                                <th style={Style?.Col}> <Btn {...{ title: 'اضافة', func: () => nav(`/budget-add-sub/${id}/${item?.id}`) }} /> </th>
                                            </tr>

                                            {item?.sub_statements?.map((v, k) => {
                                                total += v.cost_price
                                                atotal += v.approved_cost_price
                                                return (

                                                    // 
                                                    <tr key={v.id} style={{
                                                        borderColor: 'white',
                                                        backgroundColor: '#e5dfec',
                                                        borderStyle: 'solid',
                                                        borderWidth: 1,
                                                        transition: '0.2s',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            backgroundColor: 'primary.main'
                                                        },
                                                        "& td": {
                                                            borderColor: 'primary.main'
                                                        }
                                                    }}>
                                                        <td style={Style?.Col} > {key + 1}.{k + 1} </td>
                                                        <th style={Style?.Col}>
                                                            <a style={{ textDecoration: 'underline', color: 'red' }} onClick={() => nav(types?.find((i) => i.value == item?.type)?.url)}> {types?.find((i) => i.value == item?.type)?.title} </a>
                                                        </th>
                                                        <td style={Style?.Col}> {v.sub_statement_name}  </td>
                                                        <th style={Style?.Col}> {v?.unit}  </th>
                                                        <th style={Style?.Col}> {v?.quantity}  </th>
                                                        <th style={Style?.Col}> {v?.cost_unit_price}  </th>
                                                        <th style={Style?.Col}> {v?.cost_price}  </th>
                                                        <th style={Style?.Col}> {v?.approved_cost_price}  </th>
                                                        <th style={Style?.Col}> {v?.approved_quantity}  </th>
                                                        <th style={Style?.Col}> {v?.approved_unit_price}  </th>
                                                        <th style={Style?.Col}> {v?.note}  </th>
                                                    </tr>
                                                )
                                            })}

                                            <tr key={v.id} style={{
                                                borderColor: 'white',
                                                backgroundColor: '#fbd4b4',
                                                borderStyle: 'solid',
                                                borderWidth: 1,
                                                transition: '0.2s',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main'
                                                },
                                                "& td": {
                                                    borderColor: 'primary.main'
                                                }
                                            }}>
                                                <td style={Style?.Col} colSpan={6}> اجمالى </td>
                                                <td style={Style?.Col}>{total}</td>
                                                <td style={Style?.Col}>{atotal}</td>
                                                <td style={Style?.Col}></td>
                                                <td style={Style?.Col}></td>
                                                <td style={Style?.Col}></td>
                                            </tr>
                                        </React.Fragment>


                                    )

                                }

                                )}

                            </tbody>


                        </table>


                        <table style={{ marginTop: 20, width: '100%', backgroundColor: '#b6dde8' }}>
                            <tr>
                                <th style={Style?.Col}> مصاريف الإدارة العامة </th>
                                <th style={Style?.Col}> 3.00% </th>
                                <th style={Style?.Col}> {NumC((isNaN(data?.space_project?.contract_total * (3 / 100)) ? 0 : data?.space_project?.contract_total * (3 / 100)).toFixed(2))} </th>
                                <th style={Style?.Col}> {NumC(((isNaN(data?.space_project?.contract_total * (3 / 100)) ? 0 : data?.space_project?.contract_total * (3 / 100)) / data?.considered_costs).toFixed(2))} </th>
                            </tr>
                            <tr>
                                <th style={Style?.Col}> مصاريف الضمان والصيانة </th>
                                <th style={Style?.Col}>0.25%</th>
                                <th style={Style?.Col}>{NumC((isNaN(data?.space_project?.contract_total * (0.25 / 100)) ? 0 : data?.space_project?.contract_total * (0.25 / 100)).toFixed(2))}</th>
                                <th style={Style?.Col}>{((isNaN((data?.space_project?.contract_total * (0.25 / 100)) / data?.considered_costs) ? 0 : (data?.space_project?.contract_total * (0.25 / 100)) / data?.considered_costs)).toFixed(2)}</th>
                            </tr>
                            <tr>
                                <th style={Style?.Col}>  مصاريف احتياطية مدير مشروع </th>
                                <th style={Style?.Col}> - </th>
                                <th style={Style?.Col}> - </th>
                                <th style={Style?.Col}> - </th>
                            </tr>
                            <tr>
                                <th style={Style?.Col}>   مصاريف احتياطية للاداره العامه </th>
                                <th style={Style?.Col}>0.50%</th>
                                <th style={Style?.Col}>{NumC((isNaN(data?.space_project?.contract_total * (0.50 / 100)) ? 0 : data?.space_project?.contract_total * (0.50 / 100).toFixed(2)))}</th>
                                <th style={Style?.Col}>{((isNaN(data?.space_project?.contract_total * (0.50 / 100) / data?.considered_costs) ? 0 : data?.space_project?.contract_total * (0.50 / 100) / data?.considered_costs)).toFixed(2)}</th>
                            </tr>
                            <tr>
                                <th style={Style?.Col} colSpan={3}>    المتر المربع تكاليف </th>
                                <th style={Style?.Col}> {NumC(
                                    (isNaN(((data?.space_project?.contract_total * (3 / 100)) / data?.considered_costs) +
                                        ((data?.space_project?.contract_total * (0.25 / 100)) / data?.considered_costs) + data?.approved_quantity) ? 0 : ((data?.space_project?.contract_total * (3 / 100)) / data?.considered_costs) +
                                        ((data?.space_project?.contract_total * (0.25 / 100)) / data?.considered_costs) + data?.approved_quantity).toFixed(2)
                                )} </th>
                            </tr>
                            <tr>
                                <th style={Style?.Col} colSpan={3}>     المتر المربع تعاقدي </th>
                                <th style={Style?.Col}>

                                    {(data?.space_project?.contract_total / data?.space_project?.total_space_with_coefficient).toFixed(2)}

                                </th>
                            </tr>
                            <tr>
                                <th style={Style?.Col} colSpan={3}>     المتر المربع مربح </th>
                                <th style={Style?.Col}> {NumC(((((data?.space_project?.contract_total * (3 / 100)) / data?.considered_costs) +
                                    ((data?.space_project?.contract_total * (0.25 / 100)) / data?.considered_costs) + data?.approved_quantity) - (data?.space_project?.contract_total / data?.space_project?.total_space_with_coefficient)).toFixed(2))} </th>
                            </tr>
                        </table>

                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                            <table style={{ marginTop: 20, backgroundColor: '#b6dde8' }}>
                                <tr>
                                    <th style={Style?.Col}> إجمالي تكاليف الدراسة </th>
                                    <th style={Style?.Col}> {NumC(data?.considered_costs)} </th>
                                </tr>
                                <tr>
                                    <th style={Style?.Col}> إجمالي تكاليف الموازنة </th>
                                    <th style={Style?.Col}>{NumC(data?.budget_costs)}</th>
                                </tr>
                                <tr>
                                    <th style={Style?.Col}> الفرق </th>
                                    <th style={Style?.Col}>{NumC(data?.budget_costs - data?.considered_costs)}</th>
                                </tr>
                            </table>

                        </Box>

                    </>
                )
            })
        )
    }

    return (
        <Box sx={Style?.Container}>
            <TableHeader {...{ title: ' بيانات موازنة الشركة', buttonTitle: 'اضافة', page: () => nav(`/budget-add-main/${id}`) }} />
            <TableLoop />
            <Box sx={{ height: 200 }} />
            <Footer {...{ id: id }} />
        </Box>
    )
}

export default ViewBudget