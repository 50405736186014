import { InputBase } from '@mui/material'
import React from 'react'

const TextInput = ({value,set,placeholder,name,type,disabled}) => {
    const Style = {
        SearchInput : {
            input : {color:'primary.text'  },
            borderWidth:1,
            p:1,
            backgroundColor:'primary.main' , 
            '&:hover' : {  backgroundColor : 'primary.main'},
            color : 'white' ,
            width:'100%' ,
            borderRadius:1
        }
    }
  return (
    <InputBase disabled={disabled?true:false} sx={Style?.SearchInput} name={name} type={type?type:'text'} onChange={(e) => set(e.target.value)} value={value} placeholder={placeholder}/>
  )
}

export default TextInput