
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { DataTable, Footer, TableHeader } from '../../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { td, TableRow, TextField } from '@mui/material';
import { NumC, useLocalState } from '../../../config/functions';
import { useParams } from 'react-router-dom';
import { Vals } from '../../../config/Vals';

const Employees = ({nav}) => {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [totalCost, setTotalCost] = useState(0)
    const [nums, setNums] = useState(0)
    const [specializedWorksNum, setSpecializedWorksNum] = useState(0)
    const [load, setload] = useState(true)
    var sumTotalSpecializedWorks = 0
    var sumTotal = 0
    
    const [ savedToken , _ ] = useLocalState("token" );
    const {id} = useParams()

    const page = () => {
        nav(`/add-employee/${id}`)
    }

    const getData = async () => {
 
      var myHeaders = new Headers()
      myHeaders.append("Authorization", "Bearer "+savedToken)

      var requestOptions = {
          method: 'GET',
          headers:  myHeaders
      }
      
      await fetch(`${Vals?.API_KEY}get_employee_works/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setData(result?.data?.employees)
        setNums(result?.data)
        result?.data?.employees?.map((v) => {sumTotal += parseFloat(v?.total)})
        setTotal(sumTotal)
        setload(false)
      })
      .catch(error => console.log('error', error));
 
    } 

    useEffect(() => {
      getData()
    },[])
  
    const TableLoop = () => ( 

      <>
        {data?.map( ( v ) => (
          <tr key={v.id} style={{ 
            borderColor:'black' ,
            borderStyle:'solid',
            borderWidth:1, 
            transition:'0.2s' , 
            cursor : 'pointer' , 
            '&:hover' : { 
                backgroundColor : 'primary.main'
            } ,
            "& td": { 
                borderColor:'primary.main'
            },
            backgroundColor:'#b6dde8'
        }}> 
            <td   style={Style?.Col} > {v.employee_name} </td> 
            <td  style={Style?.Col} > {v.duration} </td> 
            <td  style={Style?.Col} >  {v.salary} </td> 
            <td  style={Style?.Col} > {v.number} </td> 
            <td  style={Style?.Col} > {NumC(v.total)} </td> 
          </tr>
        ))}
        <tr  style={{ 
        borderColor:'black' ,
        borderStyle:'solid',
        borderWidth:1, 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }
    }}>
          <td  style={Style?.ColTotal} colSpan={4}>
            اجمالي التكاليف الكادر الإداري
          </td>
          <td  style={Style?.Col} colSpan={1}>
            {NumC(total)}
          </td>
        </tr>
        <tr  style={{ 
        borderColor:'black' ,
        borderStyle:'solid',
        borderWidth:1, 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }
    }}>
          <td  style={Style?.ColTotal} colSpan={4}>
            اجمالي تكاليف انشائي + معماري + سباكة + كهرباء + الأعمال التخصصية
          </td>
          <td  style={Style?.Col} colSpan={1}>
            {  NumC(nums?.total_works)  }
          </td>
        </tr>
        <tr  style={{ 
        borderColor:'black' ,
        borderStyle:'solid',
        borderWidth:1, 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }
    }}>
          <td  style={Style?.ColTotal} colSpan={4}>
            هامش الكادر الإداري
          </td>
          <td  style={Style?.Col} colSpan={1}>
            {  (nums?.total / nums?.total_works ).toFixed(2)  } %
          </td>
        </tr>
        <tr  style={{ 
        borderColor:'black' ,
        borderStyle:'solid',
        borderWidth:1, 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }
    }}>
          <td  style={Style?.ColTotal} colSpan={4}>
            هامش ادارة عامة
          </td>
          <td  style={Style?.Col} colSpan={1}>
            { 2} %
          </td>
        </tr>

        <tr  style={{ 
        borderColor:'black' ,
        borderStyle:'solid',
        borderWidth:1, 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }
    }}>
          <td  style={Style?.ColTotal} colSpan={4}>
             هامش إدارة عامة + كادر إداري				
          </td>
          <td  style={Style?.Col} colSpan={1}>
          {  ( (nums?.total / nums?.total_works ) + 2).toFixed(2)  } %
          </td>
        </tr>
      </>
    )
 
  const Header = [
    { title : 'الاسم' } ,
    { title : 'المدة' } ,
    { title : 'الراتب' } ,
    { title : 'العدد' } ,
    { title : 'المجموع' } ,
  ]
 
  if (!load)
  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:'الكوادر' , buttonTitle : 'اضافة' , buttonIcon : <AddIcon /> , page : page }} />
      <table style={Style?.Table}>
      <thead>
        <tr style={Style?.TableRow} className='lgray'>
          { Header?.map((v,k) => ( <th key={k} style={Style?.Col} > {v.title} </th> )) }
        </tr>
      </thead>
      <tbody>
      <TableLoop />
      </tbody>
    </table>
    <Footer {...{id:id}}/>

    </Box>
  )
}

export default Employees