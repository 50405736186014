
export const Style = {
    
    Container : {
        paddingRight : 5 ,
        paddingLeft : 5 ,
        paddingTop : 2 ,
    } ,

    Table : { 
        marginTop:20,
        width:'100%',
        borderColor:'black'
    } ,
 
    Row : { 
        borderColor:'white' ,
        borderStyle:'solid',
        borderWidth:1, 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }
    } ,

    MultiRow : {
        borderWidth:0,
        borderLeft : '1px solid #c2c2c2',
        height:'100%',
        color:'white',
        padding:15
    } ,
    MultiRowContainer : {
        display:'flex',
        flexDirection:'row',
        height:'100%',
        width:'100%',
    },
    Col : {
        borderColor:'#c2c2c2' ,
        borderStyle:'solid',
        borderWidth:1, 
        color:'black',
        textAlign:'center',
        padding:10
    } ,

    InnerCol : {
        borderColor:'#c2c2c2' ,
        borderStyle:'solid',
        borderWidth:1, 
        color:'white',
        textAlign:'center',
        padding:0
    } ,

    SearchInput : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderColor:'red',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        color : 'white' ,
        width:'100%' ,
    } ,

    ColTotal :{
        borderColor:'#c2c2c2' ,
        borderStyle:'solid',
        borderWidth:1, 
        color:'white',
        textAlign:'left',
        padding:10
    },

}

