import React from 'react';
import axios from 'axios';
import Router from './Router';
import { DialogAlert, SideBar, TopBar } from '../components';
import { useData } from './Provider';
import { Login } from '../pages';
import { useLocalState } from './functions';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
axios.defaults.withCredentials = true
 
const Main = () => {
    const nav = useNavigate()
    const { showSidebar, setShowSidebar , logged,setlogged,alerts } = useData()

    if (!logged) return <Login />
    if (logged) {
        return (
            <Box sx={{  flexDirection:'row' , width:'100%', height:'100vh' , backgroundColor:'primary.secondary'}}>
                <TopBar {...{nav}} />
                <Router />
            </Box>
        )
    }


  
}

export default Main;