import { HashRouter } from 'react-router-dom'
import Provider from './config/Provider'
import Main from './config/Main'

function App() { 
  return (
    <HashRouter> 
      <Provider>
        <Main/>
      </Provider>
    </HashRouter > 
  );
}

export default App;
