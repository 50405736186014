import HomeRoundedIcon from '@mui/icons-material/HomeRounded'; 
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import CategoryIcon from '@mui/icons-material/Category';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone';
import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone';

export const LeftbarItems =  [

    {

        title : 'لوحة التحكم',
        
        list : [
            {
                title : 'الرئيسية',
                dropdown : [],
                icon : <HomeRoundedIcon sx={{color:'common'}} />,
                link : '/home/',
            },

            {
                title : 'المشاريع',
                icon : <LocalLibraryOutlinedIcon  />,
                dropdown : [
                    {
                        title : 'القائمة',
                        link : '/projects/'
                    },

                    {
                        title : 'اضافة جديد',
                        link : '/add-project/'
                    },
                    
                ],
            },

            // {
            //     title : 'تسجيل الخروج',
            //     dropdown : [],
            //     icon : <LoginRoundedIcon />,
            //     link : '',
            // },

        ],
    },

  
]

 