import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Vals } from '../../../config/Vals';
import { DataTable, Footer, TableHeader } from '../../../components'
import { Style } from './Style'
import { NumC, useLocalState } from '../../../config/functions';
import Btn from '../../../components/Btn/Btn';

const AllElectric = ({ nav }) => {

    const [data, setData] = useState([])
    const [Totals, setTotals] = useState([])
    const { id } = useParams()
    const [savedToken, _] = useLocalState("token")

    var totals = []
    const [space, setSpace] = useState(0)

    const getSpace = () => {


        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + savedToken);

        var raw = "";

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${Vals?.API_KEY}get_all_space_statement/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => setSpace(result?.data?.project_stats[0]?.total_space_with_coefficient))
            .catch(error => console.log('error', error));

    }

    useEffect(() => {
        getSpace()
    }, [])

    const getData = async () => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + savedToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        await fetch(`${Vals?.API_KEY}get_all_electrical/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                var a = 0
                result?.data?.statements?.map((item) => {
                    item?.sub_statements?.map((v) => {
                        a += v.considered_cost_price
                    })
                    setTotals(prevTotals => [...prevTotals, { total: a, id: item?.id }]);
                })
                setData(result?.data)
                console.log(Totals)
            })
            .catch(error => console.log('error', error))
    }

    useEffect(() => {
        getData()
        console.log(Totals)
        // console.log(Totals?.find((item) => item?.id == 1))
    }, [])


    const TableLoop = () => (
        [data]?.map((v, key) => (
            <table style={Style?.Table} key={key}>
                <thead>

                </thead>
                <tbody>

                    {v?.statements?.map((item, key) => (
                        <React.Fragment key={key}>
                            <tr style={{
                                borderColor: 'black',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                transition: '0.2s',
                                cursor: 'pointer',
                                backgroundColor: '#b6dde8',
                                '&:hover': {
                                    backgroundColor: 'primary.main'
                                },
                                "& td": {
                                    borderColor: 'primary.main'
                                }
                            }}>
                                <th style={Style?.Col} > {key + 1} </th>
                                <th style={Style?.Col} > {item.main_electrical_name} </th>
                                <th style={Style?.Col}> الوحدة </th>
                                <th style={Style?.Col}> الكمية </th>
                                <th style={Style?.Col}> سعر الوحدة </th>
                                <th style={Style?.Col}> التكلفة المدروسة </th>
                                <th style={Style?.Col}> <Btn {...{ title: 'اضافة', func: () => nav(`/electric-add-sub/${id}/${item?.id}`) }} /> </th>
                            </tr>
                            {/*  */}
                            {item?.sub_statements?.map((v, k) => (
                                <tr key={v.id} style={{
                                    borderColor: 'black',
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    transition: '0.2s',
                                    cursor: 'pointer',
                                    backgroundColor: '#e5dfec',
                                    '&:hover': {
                                        backgroundColor: 'primary.main'
                                    },
                                    "& td": {
                                        borderColor: 'primary.main'
                                    }
                                }}>
                                    <td style={Style?.Col} > {key + 1}.{k + 1} </td>
                                    <td style={Style?.Col} > {v.sub_electrical_name} </td>
                                    <td style={Style?.Col} > {v.unit} </td>
                                    <td style={Style?.Col} > {v.quantity} </td>
                                    <td style={Style?.Col} > {v.cost_unit_price} </td>
                                    <td style={Style?.Col} > {v.considered_cost_price} </td>
                                </tr>
                            ))}

                            <tr style={{ backgroundColor: '#d8d8d8' }}>
                                <td style={Style?.ColTotal} colSpan={5}> إجمالي مواد الكهرباء </td>
                                <td style={Style?.Col}> {NumC((Totals.find((i) => i.id == item.id)?.total).toFixed(2))}  </td>
                                <th style={Style?.Col}>التكلفة المبدئية</th>
                            </tr>

                            <tr style={{ backgroundColor: '#d8d8d8' }}>
                                <td style={Style?.ColTotal} colSpan={5}> تكلفة مواد الكهرباء على المتر </td>
                                <td style={Style?.Col}>  {NumC((space * Totals.find((i) => i.id == item.id)?.total).toFixed(2))} </td>
                                <td style={Style?.Col}>{NumC((space * 40).toFixed(2))} </td>
                            </tr>

                            <tr style={{ backgroundColor: '#d8d8d8' }}>
                                <td style={Style?.ColTotal} colSpan={4}> إدارة عامة +كادر إداري </td>
                                <td style={Style?.Col} colSpan={1}> {data?.space_project?.manage_cost}% </td>
                                <td style={Style?.Col}> {(data?.space_project?.manage_cost / 100) * Totals.find((i) => i.id == item.id)?.total}</td>
                                <td style={Style?.Col}>40</td>
                            </tr>

                            <tr style={{ backgroundColor: '#d8d8d8' }}>
                                <td style={Style?.ColTotal} colSpan={4}> مربح	 </td>
                                <td style={Style?.Col} colSpan={1}> {data?.space_project?.profit}% </td>
                                <td style={Style?.Col} colSpan={1}> {(data?.space_project?.profit / 100) * Totals.find((i) => i.id == item.id)?.total}  </td>
                            </tr>

                            <tr style={{ backgroundColor: '#d8d8d8' }}>
                                <td style={Style?.ColTotal} colSpan={5}> إجمالي مع الإدارة والمربح	 </td>
                                <td style={Style?.Col}> {
                                    NumC(((data?.space_project?.profit * Totals.find((i) => i.id == item.id)?.total) +
                                        (data?.space_project?.manage_cost * Totals.find((i) => i.id == item.id)?.total) +
                                        (space * Totals.find((i) => i.id == item.id)?.total)).toFixed(2))}
                                </td>
                            </tr>



                        </React.Fragment>


                    ))}



                </tbody>


            </table>
        ))
    )

    return (
        <Box sx={Style?.Container}>
            <TableHeader {...{ title: 'اعمال الكهرباء', buttonTitle: 'اضافة', page: () => nav(`/electric-add-main/${id}`) }} />
            <TableLoop />
            {/* <table  style={Style?.Table}>
                <tbody>
                    <tr>
                        <td  style={Style?.ColTotal} colSpan={5}> اجمالى أعمال الكهرباء	  </td>
                        <td style={Style?.Col}>  </td>
                    </tr>
                    <tr>
                        <td  style={Style?.ColTotal} colSpan={5}> تكلفة أعمال الكهرباء على المتر	  </td>
                        <td style={Style?.Col}>  </td>
                    </tr>
                    <tr>
                        <td  style={Style?.ColTotal} colSpan={4}> إدارة عامة +كادر إداري			  </td>
                        <td  style={Style?.ColTotal} colSpan={1}> 10.00%	  </td>
                        <td style={Style?.Col} colSpan={1}> 11 </td>
                    </tr>

                    <tr>
                        <td  style={Style?.ColTotal} colSpan={4}> مربح			  </td>
                        <td  style={Style?.ColTotal} colSpan={1}> 15.00%	  </td>
                        <td style={Style?.Col} colSpan={1}> 11 </td>
                    </tr>
                    <tr>
                        <td  style={Style?.ColTotal} colSpan={5}> إجمالي مع الإدارة والمربح		  </td>
                        <td style={Style?.Col} colSpan={1}> 11 </td>
                    </tr>
                </tbody>
            </table> */}

            <table style={{
                marginTop: 20,
                width: '100%', backgroundColor: '#fabf8f'
            }}>
                <tbody>
                    <tr>
                        <th style={Style?.ColTotal} colSpan={5}> اجمالى أعمال الكهرباء	  </th>
                        <th style={Style?.Col}> {NumC((data?.considered_cost_price))} </th>
                    </tr>
                    <tr>
                        <th style={Style?.ColTotal} colSpan={5}> تكلفة أعمال الكهرباء على المتر	  </th>
                        <th style={Style?.Col}> {NumC((isNaN(data?.considered_cost_price * space) ? 0 : data?.considered_cost_price * space).toFixed(2))}  </th>
                    </tr>
                    <tr>
                        <th style={Style?.ColTotal} colSpan={4}> إدارة عامة +كادر إداري			  </th>
                        <th style={Style?.ColTotal} colSpan={1}> {data?.space_project?.manage_cost}%  </th>
                        <th style={Style?.Col} colSpan={1}> {(data?.considered_cost_price * (10 / 100)).toFixed(2)} </th>
                    </tr>

                    <tr>
                        <th style={Style?.ColTotal} colSpan={4}> مربح			  </th>
                        <th style={Style?.ColTotal} colSpan={1}>  {data?.space_project?.profit}%   </th>
                        <th style={Style?.Col} colSpan={1}> {(data?.considered_cost_price * (15 / 100)).toFixed(2)} </th>
                    </tr>
                    <tr>
                        <th style={Style?.ColTotal} colSpan={5}> إجمالي مع الإدارة والمربح		  </th>
                        <th style={Style?.Col} colSpan={1}> {
                            NumC(((data?.considered_cost_price * (10 / 100)) +
                                (data?.considered_cost_price * (15 / 100)) +
                                data?.considered_cost_price).toFixed(2))
                        } </th>
                    </tr>
                </tbody>
            </table>
            <Box sx={{ height: 500 }} />
            <Footer {...{ id: id }} />

        </Box>
    )
}

export default AllElectric