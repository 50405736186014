import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { TableHeader, TextInput } from '../../../components'
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Vals } from '../../../config/Vals';
import Btn from '../../../components/Btn/Btn';
import { useParams } from 'react-router-dom';
import { useLocalState } from '../../../config/functions';

const AddSubElectric = ({nav}) => {
    
  const [ constInputs , setConstInputs ] = useState([])
  const [constInputsVal, setConstInputsVal] = useState({});
  const [typev, setTypeV] = useState('');
  
  const [ savedToken , _ ] = useLocalState("token" );
  
  const {id,mid} = useParams()

  const Style = {
    SearchInput : {
      input : {color:'primary.text' , },
      'textarea' : {color:'primary.text' , },
      borderWidth:1,
      borderColor:'red',
      backgroundColor:'primary.main' , 
      '&:hover' : {  backgroundColor : 'primary.main'},
      color : 'white' ,
      width:'100%' ,
    }
  }

  const c = []

  const page = () => {
    
 
    [constInputsVal]?.map((item,key) => {
        for (var i = 0 ; i < constInputs?.length ; i++) {
          c.push({
            "sub_electrical_name": Object(item)['sub_statement_name_'+i]?.text,
            "unit": Object(item)['unit_'+i]?.text,
            "quantity": parseFloat(Object(item)['quantity_'+i]?.text),
            "cost_unit_price": parseFloat(Object(item)['cost_price_'+i]?.text),
            "considered_cost_price" : parseFloat(Object(item)['cost_price_'+i]?.text) * parseFloat(Object(item)['quantity_'+i]?.text)
          });
        }
      });
   

    const data = 
    JSON.stringify(
      {
        "main_electrical_id":mid,
        "project_id":id,
        "sub_bands":c
    }
    )
 
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+savedToken);
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
      redirect: 'follow'
    };
    
    fetch(`${Vals?.API_KEY}add_sub_electrical`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.status) {
          nav(`/electric-all/${id}`)
        } else {
          alert(result?.data?.image[0])
        }
      })
      .catch(error => console.log('error', error));
  }

    const handleInputChangeConst = (event) => {
        const newFormData = {};
        const { name, value } = event.target;
        newFormData[name] = {text : value};
        setConstInputsVal({ ...constInputsVal, ...newFormData });
    };

  const deleteConst = (num) => {
    const filteredItems = constInputs.filter((item, index) => index !== num);
    setConstInputs(filteredItems)
   }

   const AddConstractor = () => {
    setConstInputs([...constInputs , {title : `مقاول ` , key : constInputs?.length+1 }] )
  }

  useEffect(() => {
    AddConstractor()
  }, [])
  
 
 
  return (
    <Box p={2}>
      <TableHeader {...{ title:'اضافة اعمال الكهرباء' , buttonTitle : 'اضافة' , buttonIcon : <AddIcon /> , page : page }} />

      <Box sx={{justifyContent:'flex-start',display:'flex',alignItems:'flex-start' }}>

        <Box sx={{ width:{sx:'100%',md:'50%'} ,  }} p={2}>
            { constInputs?.map((item,key) => (
              <Box sx={{mb:2,pt:2,borderWidth:0,borderTopWidth:1,borderStyle:'solid',borderColor:'white'}} key={key}>
                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1} fontSize={25}> اسم البند </Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`sub_statement_name_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`sub_statement_name_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1} fontSize={25}> الوحدة </Typography>
                    <InputBase 
                      value={constInputsVal[`unit_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`unit_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'
                      />
                </Box>

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1} fontSize={25}> الكمية </Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`quantity_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`quantity_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>
                
 
                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1} fontSize={25}>  سعر الوحدة التكلفة    </Typography>
                    <InputBase type='text' 
                      value={constInputsVal[`cost_price_${key}`]?.text} 
                      onChange={handleInputChangeConst} 
                      name={`cost_price_${key}`} 
                      sx={Style?.SearchInput} 
                      variant="outlined" 
                      autoComplete='off'/>
                </Box>
 

                <Box sx={{mb:2}}>
                    <Typography color='primary.text' mb={1} fontSize={25}>  التكلفة المدروسة    </Typography>
                    <Typography color='primary.text' mb={1} fontSize={25}>  {isNaN(constInputsVal[`cost_price_${key}`]?.text*constInputsVal[`quantity_${key}`]?.text)?0:constInputsVal[`cost_price_${key}`]?.text*constInputsVal[`quantity_${key}`]?.text}    </Typography>
             
                </Box>
 
                {constInputs?.length > 1 && key !=0 && (
                    <Btn {...{title:'ازالة',func:() => deleteConst(key) }} />
                )}
              </Box>
          
          ) )}

            <Btn {...{title:'اضافة مزيد' , func:AddConstractor}} />

        </Box>

        <Box sx={{ width:{sx:'100%',md:'25%'} , }} p={2}>

        </Box>

        <Box sx={{ width:{sx:'100%',md:'25%'} , }} p={2}>
        
        </Box>

      </Box>

    </Box>
  )
}

export default AddSubElectric