export const Style = {
    TagContainer : {
        height:'auto',
        width:'100%',
        justifyContent:'flex-start',
        display:'flex',
        alignItems:'flex-start',
        flexDirection:'column'
    } , 
    tag : {
        float:'right',
        width:'auto',
        p:1,
        justifyContent:'center',
        display:'flex',
        alignItems:'center',
        cursor:'pointer',
        m:1,
        borderRadius:2,
        border:'1px solid red'
    } ,
    SearchInput : {
        input : {color:'primary.text' , fontSize:25 , p:2},
        'textarea' : {color:'primary.text' , },
        borderWidth:1,
        borderColor:'red',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        color : 'white' ,
        width:'100%' ,
      }
}