
import React from 'react'
import { AppBar,Badge ,Button,IconButton  } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import { useData } from '../../config/Provider';
import HomeIcon from '@mui/icons-material/Home';
import { useLocalState } from '../../config/functions';
import LogoutIcon from '@mui/icons-material/Logout';
const TopBar = ({nav}) => {

    const { showSidebar, setShowSidebar , logged,setlogged} = useData()

    const hideSidebar = () => {
        setShowSidebar(!showSidebar)
    }
 
    function notificationsLabel(count) {
        if (count === 0) {
            return 'no notifications';
        }
        if (count > 99) {
            return 'more than 99 notifications';
        }
        return `${count} notifications`;
    }
    const [ Islogged , setIslogged ] = useLocalState("Islogged" , false);
    const [ savedToken , setSavedToken ] = useLocalState("token" , null);
    const logout = () => {
        setSavedToken(null)
        setIslogged(false)
        setlogged(false)
        nav('login')
    }
 
  return (
        <AppBar position='sticky'  sx={{top:0,zIndex:1,boxShadow:'0',backgroundColor:'primary.secondary'}}>

            <Container maxWidth="100">
              
                <Box sx={{display:'flex',justifyContent: 'space-between',p: 1,m: 1}}>

                    <Stack spacing={0} direction="row">
                        
                        <Button 
                            onClick={() => nav(`/home/`)} 
                            sx={{color:'primary.text',borderColor:'primary.red',m:1,fontSize:20}} 
                            variant="outlined" >
                                <HomeIcon sx={{color:'primary.text',ml:2,mr:0}}/> الرئيسية  
                        </Button>

                        <Button 
                            onClick={logout} 
                            sx={{color:'primary.text',borderColor:'primary.red',m:1,fontSize:20}} 
                            variant="outlined" >
                                <LogoutIcon sx={{color:'primary.text',ml:2,mr:0}}/> تسجيل الخروج  
                        </Button>

                        
                     
                    </Stack>
                    
                    <Stack spacing={2} direction="row">
                <img src={'./logo.jpeg'} width={150}/>

                    </Stack>

                </Box>

            </Container>

        </AppBar>
  )
}

 export default TopBar