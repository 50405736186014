import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Vals } from '../../../config/Vals';
import { DataTable, Footer, TableHeader, TextInput } from '../../../components'
import { Style } from './Style'
import { useLocalState } from '../../../config/functions';
import Btn from '../../../components/Btn/Btn';
import './style.css'

const ViewEquipment = ({ nav }) => {

    const [data, setData] = useState([])
    const { id } = useParams()
    const [savedToken, _] = useLocalState("token");
    const [space, setSpace] = useState(0)
    const [lengths, setlength] = useState({})


    var total1 = 0
    var total2 = 0
    var total3 = 0
    const getSpace = () => {


        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + savedToken);

        var raw = "";

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${Vals?.API_KEY}get_all_space_statement/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => setSpace(result?.data?.project_stats[0]?.total_space_with_coefficient))
            .catch(error => console.log('error', error));

    }

    useEffect(() => {
        getSpace()
    }, [])

    const getData = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + savedToken);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        return fetch(`${Vals?.API_KEY}get_all_equipment/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setData(result)
                const countLength = result?.data?.statements?.reduce(
                    (acc, curr) => {
                        if (curr.material.length > acc.materialMaxLength) {
                            acc.materialMaxLength = curr.material.length;
                        }
                        if (curr.contractor.length > acc.contractorMaxLength) {
                            acc.contractorMaxLength = curr.contractor.length;
                        }
                        return acc;
                    },
                    { materialMaxLength: 0, contractorMaxLength: 0 }
                )
                setlength(countLength)
            });

    }

    const mc = lengths?.materialMaxLength

    useEffect(() => {
        getData()
        console.log(mc)
    }, [])

    return (
        <Box sx={Style?.Container}>
            <TableHeader {...{ title: 'تجهيزات الموقع', buttonTitle: 'اضافة', page: () => nav(`/equipment-add-main/${id}`) }} />
            <Box sx={Style?.ContainerTable}>
                <table style={Style?.Table}>
                    <thead>
                        <tr>
                            <th style={Style?.Col} rowSpan={3} className='gray'>رقم البند</th>
                            <th style={Style?.Col} rowSpan={3} className='gray'>وصف الأعمال</th>
                            <th style={Style?.Col} rowSpan={3} className='gray'>الوحدة</th>
                            <th style={Style?.Col} rowSpan={3} className='gray'>الكمية المبدئيه</th>
                            <th style={Style?.Col} rowSpan={3} className='gray'>الكمية الفعلية</th>
                            {mc > 0 && (
                                <th style={Style?.Col} colSpan={mc + 1} className='gray'>التكاليف المباشرة</th>
                            )}
                            <th style={Style?.Col} colSpan={2} className='gray'>التكاليف الغير مباشرة</th>
                            <th style={Style?.Col} colSpan={3} className='gray'>الأسعار الفردية</th>
                            <th style={Style?.Col} colSpan={3} className='gray'> الأسعار الإجمالية</th>

                        </tr>
                        <tr>
                            {lengths?.materialMaxLength > 0 && (
                                <th style={Style?.Col} rowSpan={2} className='lgray' colSpan={lengths?.materialMaxLength}>المواد</th>
                            )}

                            <th style={Style?.Col} rowSpan={2} colSpan={1} className='lgray'>التكاليف المباشرة</th>

                            <td style={Style?.Col} className='gold'> الإدارة العامة والضمان </td>
                            <td style={Style?.Col} className='gold'>هامش الربح</td>

                            <td style={Style?.Col} rowSpan={2} className='lgray'>السعر الاسترشادي</td>
                            <td style={Style?.Col} rowSpan={2} className='lgray'>السعر المقترح حسب الدراسة</td>
                            <td style={Style?.Col} rowSpan={2} className='lgray'>السعر المعتمد</td>

                            <td style={Style?.Col} rowSpan={2} className='lgray'> إجمالي التكلفة المباشرة </td>
                            <td style={Style?.Col} rowSpan={2} className='lgray'> إجمالي السعر المقترح </td>
                            <td style={Style?.Col} rowSpan={2} className='lgray'> إجمالي السعر المعتمد </td>
                        </tr>
                        <tr>

                            <td style={Style?.Col} className='gold'>10%</td>
                            <td style={Style?.Col} className='gold'>15%</td>
                        </tr>
                    </thead>
                    {/* main row */}
                    {data?.data?.statements?.map((item, key) => {
                        var cost_meter_price_total = 0
                        var suggested_meter_price_total = 0
                        var approved_meter_price_total = 0
                        var managetotal = 0
                        var profittotal = 0
                        var rowa = 0
                        var suggprice = 0
                        return (
                            <Fragment key={key}>
                                <thead >
                                    <tr>
                                        <td style={Style?.Col} rowSpan={3} className='light'> {key + 1} <Btn {...{ title: 'اضافة', func: () => nav(`/equipment-add-sub/${id}/${item?.id}`) }} /> </td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> {item?.main_equipment_name} </td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> الوحدة</td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> الكمية الافتراضية</td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> الكمية المعتمده</td>
                                        {item?.material?.map((item, key) => <td className='light' key={key} style={Style?.Col} rowSpan={3}> {item?.material_name} </td>)}
                                        <td style={Style?.Col} rowSpan={3} className='light'>  التكاليف المباشرة</td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> إدارة عامة</td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> مربح </td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> السعر الاسترشادي</td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> السعر المقترح</td>
                                        <td style={Style?.Col} rowSpan={3} className='light'> السعر المعتمد</td>
                                        <td style={Style?.Col} className='light'>  إجمالي التكلفة </td>
                                        <td style={Style?.Col} className='light'>   إجمالي السعر المقترح  </td>
                                        <td style={Style?.Col} className='light'>   إجمالي السعر المعتمد  </td>
                                    </tr>
                                    <tr>
                                        <td style={Style?.Col} className='cyan'> {(data?.data?.sum_cost_meter_price / space).toFixed(2)} </td>
                                        <td style={Style?.Col} className='cyan'> {(data?.data?.sum_suggested_meter_price / space).toFixed(2)} </td>
                                        <td style={Style?.Col} className='cyan'> {(data?.data?.sum_approved_meter_price / space).toFixed(2)} </td>
                                    </tr>
                                    <tr>
                                        <td style={Style?.Col} className='light'> سعر المتر للتكلفه </td>
                                        <td style={Style?.Col} className='light'> سعر المتر للمفترح </td>
                                        <td style={Style?.Col} className='light'> سعر المتر للمعتمد </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item?.sub_statements?.map((v, k) => {

                                        cost_meter_price_total += v.cost_meter_price
                                        suggested_meter_price_total += v.suggested_meter_price
                                        approved_meter_price_total += v.approved_meter_price

                                        var row_total = 0
                                        suggprice += v?.suggested_price
                                        {
                                            item?.material?.map((a, key) => {
                                                row_total += isNaN(v?.material_price?.find((i) => i?.material_id == a?.id)?.price) ? 0 : v?.material_price?.find((i) => i?.material_id == a?.id)?.price
                                            })
                                        }

                                        {
                                            item?.contractor?.map((a, key) => {
                                                row_total += isNaN(v?.contractor_price?.find((i) => i?.contractor_id == a?.id)?.price) ? 0 : v?.contractor_price?.find((i) => i?.contractor_id == a?.id)?.price
                                            })
                                        }

                                        rowa += row_total
                                        managetotal += v?.manage_cost
                                        profittotal += v?.profit

                                        total1 += rowa
                                        total2 += managetotal
                                        total3 += profittotal
                                        return (
                                            <Fragment key={k} >
                                                <tr>
                                                    <td style={Style?.Col} className='lpurple'> {key + 1}.{k + 1} </td>
                                                    <td style={Style?.Col} className='lpurple'> {v?.sub_equipment_name} </td>
                                                    <td style={Style?.Col} className='lpurple'> {v?.unit} </td>
                                                    {/* الكمية الافتراضية */}
                                                    <td style={Style?.Col} className='lpurple'> {v.quantity} </td>
                                                    {/* الكمية المعتمده */}
                                                    <td style={Style?.Col} className='lpurple'> {v.approved_quantity} </td>
                                                    {item?.material?.map((a, key) => <td className='lpurple' key={key} style={Style?.Col} >  {v?.material_price?.find((i) => i?.material_id == a?.id)?.price}  </td>)}
                                                    <td style={Style?.Col} className='lpurple'> {row_total}  </td>
                                                    <td style={Style?.Col} className='lpurple'> {v?.manage_cost} </td>
                                                    <td style={Style?.Col} className='lpurple'> {v?.profit}   </td>
                                                    {/* السعر الاسترشادي */}
                                                    <td style={Style?.Col} className='lpurple'></td>
                                                    <td style={Style?.Col} className='lpurple'>   {v.suggested_price}  </td>
                                                    <td style={Style?.Col} className='lpurple'>   {v.approved_price}  </td>

                                                    <td style={Style?.Col} className='yellow'> {v?.cost_meter_price} </td>
                                                    <td style={Style?.Col} className='red'> {v?.suggested_meter_price} </td>
                                                    <td style={Style?.Col} className='orange'> {v?.approved_meter_price} </td>
                                                </tr>

                                            </Fragment>
                                        )
                                    })}

                                </tbody>
                                <tbody>

                                    <tr>
                                        <td style={Style?.Col} className='light' colSpan={mc + 5}></td>
                                        <td style={Style?.Col} className='light' colSpan={1}>{rowa}</td>
                                        <td style={Style?.Col} className='light' colSpan={1}>{managetotal}</td>
                                        <td style={Style?.Col} className='light' colSpan={1}>{profittotal} </td>
                                        <td style={Style?.Col} className='light' colSpan={1}></td>
                                        <td style={Style?.Col} className='light' colSpan={1}>{profittotal + managetotal}</td>
                                        <td style={Style?.Col} className='light' colSpan={1}></td>
                                        <td style={Style?.Col} className='light'>{cost_meter_price_total}</td>
                                        <td style={Style?.Col} className='light'>{suggested_meter_price_total}</td>
                                        <td style={Style?.Col} className='light'>{approved_meter_price_total}</td>
                                    </tr>

                                    <tr>
                                        <td style={Style?.Col} className='lgray' colSpan={mc + 5}></td>
                                        <td style={Style?.Col} className='lgray' colSpan={1}> اجمالى التكاليف المباشره </td>
                                        <td style={Style?.Col} className='lgray' colSpan={1}> اجمالى  الاداره العامه </td>
                                        <td style={Style?.Col} className='lgray' colSpan={1}> اجمالى المربح </td>
                                        <td style={Style?.Col} className='lgray' colSpan={1}></td>
                                        <td style={Style?.Col} className='lgray' colSpan={1}> اجمالى التكاليف الغير مباشره</td>
                                        <td style={Style?.Col} className='lgray' colSpan={1}></td>
                                        <td style={Style?.Col} className='lgray'>اجمالى التكلفه للبند الرئيسى</td>
                                        <td style={Style?.Col} className='lgray'>اجمالى المقترح للبند الرئيسى</td>
                                        <td style={Style?.Col} className='lgray'>اجمالى المعتمد للبند الرئيسى</td>
                                    </tr>


                                </tbody>

                            </Fragment>
                        )
                    })}

                    <tbody>

                        <tr>
                            <td style={Style?.aCol} colSpan={mc + 11} className='lgray'> اجمالى سعر المتر </td>
                            <td style={Style?.Col} className='lgray'> {(isNaN(data?.data?.sum_cost_meter_price / space) ? 0 : data?.data?.sum_cost_meter_price / space).toFixed(2)} </td>
                            <td style={Style?.Col} className='lgray'> {(isNaN(data?.data?.sum_suggested_meter_price / space) ? 0 : data?.data?.sum_suggested_meter_price / space).toFixed(2)} </td>
                            <td style={Style?.Col} className='lgray'> {(isNaN(data?.data?.sum_approved_meter_price / space) ? 0 : data?.data?.sum_approved_meter_price / space).toFixed(2)} </td>
                        </tr>
                        <tr>
                            {/* ssssssssssssss */}


                            <td style={Style?.Col} className='lgray' colSpan={mc + 5}></td>
                            <td style={Style?.Col} className='lgray' colSpan={1}> {total1} </td>
                            <td style={Style?.Col} className='lgray' colSpan={1}> {total2} </td>
                            <td style={Style?.Col} className='lgray' colSpan={1}> {total3} </td>
                            <td style={Style?.Col} className='lgray' colSpan={1}></td>
                            <td style={Style?.Col} className='lgray' colSpan={1}> {total2 + total3}</td>
                            <td style={Style?.Col} className='lgray' colSpan={1}></td>



                            <td style={Style?.Col} className='lgray'> {isNaN(data?.data?.sum_cost_meter_price) ? 0 : data?.data?.sum_cost_meter_price} </td>
                            <td style={Style?.Col} className='lgray'> {isNaN(data?.data?.sum_suggested_meter_price) ? 0 : data?.data?.sum_suggested_meter_price} </td>
                            <td style={Style?.Col} className='lgray'> {isNaN(data?.data?.sum_approved_meter_price) ? 0 : data?.data?.sum_approved_meter_price} </td>
                        </tr>

                        <tr>
                            <td style={Style?.Col} className='lgray' colSpan={mc + 5}></td>
                            <td style={Style?.Col} className='lgray' colSpan={1}> اجمالى التكاليف المباشره </td>
                            <td style={Style?.Col} className='lgray' colSpan={1}> اجمالى  الاداره العامه </td>
                            <td style={Style?.Col} className='lgray' colSpan={1}> اجمالى المربح </td>
                            <td style={Style?.Col} className='lgray' colSpan={1}></td>
                            <td style={Style?.Col} className='lgray' colSpan={1}> اجمالى التكاليف الغير مباشره</td>
                            <td style={Style?.Col} className='lgray' colSpan={1}></td>
                            <td style={Style?.Col} className='lgray'>اجمالى التكلفه للبند الرئيسى</td>
                            <td style={Style?.Col} className='lgray'>اجمالى المقترح للبند الرئيسى</td>
                            <td style={Style?.Col} className='lgray'>اجمالى المعتمد للبند الرئيسى</td>
                        </tr>

                    </tbody>

                </table>
            </Box>
            <Footer {...{ id: id }} />

        </Box>
    )
}

export default ViewEquipment