export const Style = {
    Container : {
        display:'flex',
        backgroundColor:'primary.main',
        height:'100vh',
        justifyContent:'center',
        alignItems:'center' ,
        flexDirection : 'column'
    } ,
    FormContainer : {
        display:'flex',
        alignItems:'center' ,
        width:'30%',
        justifyContent:'space-between',
        padding:5,
        backgroundColor:'#fafafa',
        flexDirection : 'column',
        borderStyle:'solid',
        borderColor:'primary.border',
        borderWidth:1,
        borderRadius:1
    } ,
    Form : {
        justifyContent:'center',
        display:'flex',
        flexDirection : 'column',
        alignItems:'center' ,
    } ,
    Input : {
        input : {color:'primary.text' ,width:300 , textAlign:'center' },
        borderWidth:1,
        textAlign:'center',
        borderColor:'primary.border',
        backgroundColor:'white' , 
        '&:hover' : {  backgroundColor : 'white'},
        color : 'white' ,
        width:'100%' ,
        borderStyle:'solid',
        mt:2,
        p:1,
        borderRadius:1
    } ,
    Button : {
        '&:hover':{
            backgroundColor:'white'
        }
    }
}