
import { createTheme } from '@mui/material/styles';
import Jazeera from "../fonts/jazeera.ttf";

export const Dark = createTheme({
  typography: {
    fontFamily: "jazeera, sans-serif",
  },
    palette: {
      primary: {
        main: '#c9d8e2',
        // secondary : '#282424',
        secondary : '#e7e7e7',
        text:'#00558c',
        purple : '#ccae4e',
        Darkgold : '#E8464E',
        gold : '#2B3956',
        red : '#cb5d5d',
        DarkGreen : '#7191b7',
        border: '#3a3a3a'
      },
    },
});

export const Light = createTheme({
    palette: {
        primary: {
          main: '#fafafa',
          secondary : 'white',
          text:'black',
          red : '#cb5d5d',
          Darkgold : '#ccae4e',
          gold : '#d5b965',
          green : '#3fc79a',
          DarkGreen : '#0b815a', 
          border: '#3a3a3a'
        },
    },
  });
    