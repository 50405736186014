import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { Btn, TableHeader, TextInput } from '../../../components'
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Vals } from '../../../config/Vals';
import { useParams } from 'react-router-dom';
import { useLocalState } from '../../../config/functions';
import { Style } from './Style';

const AddSubConstruction = ({ nav }) => {

  const [title, setTitle] = useState('')
  const [mattsInputs, setMattsInputs] = useState([])
  const [constInputs, setConstInputs] = useState([])
  const [constInputsVal, setConstInputsVal] = useState({});
  const [mattsInputsVal, setMattsInputsVal] = useState({});
  const [quantity, setQuantity] = useState();
  const [transferAmount, setTransferAmount] = useState();
  const [defaultParameter, setDefaultParameter] = useState();
  const [directCost, setDirectCost] = useState();
  const [unit, setUnit] = useState();
  const [option, setoption] = useState();
  const [approvedQuantity, setApprovedQuantity] = useState();
  const [savedToken, _] = useLocalState("token");

  const { id, type, mid } = useParams()

  const [matArr, setMatArr] = useState([])
  const [constArr, setConstArr] = useState([])

  const [space, setSpace] = useState(0)

  const getSpace = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + savedToken);

    var raw = "";

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    fetch(`${Vals?.API_KEY}get_all_space_statement/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => setSpace(result?.data?.project_stats[0]?.total_space_with_coefficient))
      .catch(error => console.log('error', error))

  }

  useEffect(() => {
    getSpace()
  }, [])

  const page = () => {

    var totalC = 0
    var totalM = 0

    matArr?.map((v) => {
      totalM += v.price
    })

    constArr?.map((v) => {
      totalC += v.price
    })

    var raw = JSON.stringify({
      "main_construction_id": mid,
      "project_id": id,
      "sub_bands": [
        {
          "sub_construction_name": title,
          "unit": unit,
          "transfer_amount": transferAmount,
          "default_parameter": approvedQuantity / space,
          "quantity": (approvedQuantity / space) * space,
          "approved_quantity": approvedQuantity,
          "materials_price": matArr,
          "contractors_price": constArr,
          "direct_cost": totalC + totalM
        }
      ]
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + savedToken);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${Vals?.API_KEY}add_sub_construction`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.status) {
          nav(`/construction-all/${id}`)
        }
      })
      .catch(error => console.log('error', error));

  }

  const getData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + savedToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${Vals?.API_KEY}get_main_construction/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => setoption(result?.data))
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
  }, [])

  const addMat = (id, price) => {
    if (matArr?.find((i) => (i.material_id == id))) {
      const filter = matArr?.filter((i) => i.material_id != id)
      setMatArr(filter)
    } else {
      setMatArr([...matArr, { material_id: id, price: 0 }])
    }
  }

  const handlePriceChange = (id, price) => {
    const index = matArr.findIndex((i) => i.material_id === id);
    const newArray = [...matArr];
    newArray[index] = { ...newArray[index], price };
    setMatArr(newArray);
  }

  const addConst = (id, price) => {
    if (constArr?.find((i) => (i.contractor_id == id))) {
      const filter = constArr?.filter((i) => i.contractor_id != id)
      setConstArr(filter)
    } else {
      setConstArr([...constArr, { contractor_id: id, price: 0 }])
    }
  }

  const handleConstPriceChange = (id, price) => {
    const index = constArr.findIndex((i) => i.contractor_id === id);
    const newArray = [...constArr];
    newArray[index] = { ...newArray[index], price };
    setConstArr(newArray);
  }

  return (
    <Box p={2}>
      <TableHeader {...{ title: 'اضافة بند', buttonTitle: 'اضافة', buttonIcon: <AddIcon />, page: page }} />

      <Box sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-start' }}>
        <Box sx={{ width: { sx: '100%', md: '50%' }, }} p={2}>

          <Box sx={{ mb: 2 }}>
            <Typography color='primary.text' fontSize={25} mb={1}> اسم البند </Typography>
            <TextInput {...{ set: setTitle, value: title }} />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography color='primary.text' fontSize={25} mb={1}> الوحدة </Typography>
            <TextInput {...{ set: setUnit, value: unit }} />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography color='primary.text' mb={1} fontSize={25} > كمية التحويل </Typography>
            <TextInput {...{ set: setTransferAmount, value: transferAmount }} />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography color='primary.text' fontSize={25} mb={1}> الكمية المعتمدة </Typography>
            <TextInput {...{ set: setApprovedQuantity, value: approvedQuantity }} />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography color='primary.text' fontSize={25} mb={1}>  الكمية الافتراضية</Typography>
            <Typography color='primary.text' fontSize={25} mb={1}> {isNaN((approvedQuantity / space) * space) ? 0 : ((approvedQuantity / space) * space).toFixed()} </Typography>
          </Box>



          <Box sx={{ mb: 2 }}>
            <Typography color='primary.text' fontSize={25} mb={1}> المعلمة الافتراضية </Typography>
            <Typography color='primary.text' fontSize={25} mb={1}> {isNaN((approvedQuantity / space)) ? 0 : (approvedQuantity / space).toFixed(2)} </Typography>
          </Box>

        </Box>

        <Box sx={{ width: { sx: '100%', md: '25%' }, }} p={2}>

          <Box sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

            <Box sx={{ mb: 2 }}>
              {/* <Btn {...{title:'اضافة مقاول' , func:AddConstractor}} /> */}
              <Typography color='primary.text' fontSize={25}> اضافة مقاول </Typography>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Box sx={Style?.TagContainer}>

                {option?.contractors?.map((item, key) => (
                  constArr?.find((i) => i.contractor_id == item?.id) ?
                    <Box sx={{ mb: 2, }} key={key}>
                      <Typography color='primary.text' mb={1} fontSize={25}> {item?.contractor_name} </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <InputBase
                          type="text"
                          sx={Style?.SearchInput}
                          variant="outlined"
                          autoComplete="off"
                          value={constArr?.find((i) => i.contractor_id === item?.id)?.price}
                          onChange={(e) =>
                            handleConstPriceChange(item?.id, parseInt(e.target.value))
                          }
                        />
                        <Btn {...{ title: 'ازالة', func: () => addConst(item?.id) }} />
                      </Box>
                    </Box>
                    :
                    <Box key={key} sx={Style?.tag} onClick={() => addConst(item?.id)}>
                      <Typography fontSize={25} color='primary.text'> {item?.contractor_name} </Typography>
                    </Box>
                ))}
              </Box>
            </Box>

          </Box>


        </Box>

        <Box sx={{ width: { sx: '100%', md: '25%' }, }} p={2}>
          <Box sx={{ mb: 2 }}>
            <Typography color='primary.text' fontSize={25}> اضافة مادة </Typography>
          </Box>
          <Box sx={Style?.TagContainer}>

            {option?.materials?.map((item, key) => (
              matArr?.find((i) => i.material_id == item?.id) ?
                <Box sx={{ mb: 2, }} key={key}>
                  <Typography color='primary.text' mb={1} fontSize={25}> {item?.material_name} </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <InputBase
                      type="text"
                      sx={Style?.SearchInput}
                      variant="outlined"
                      autoComplete="off"
                      value={matArr?.find((i) => i.material_id === item?.id)?.price}
                      onChange={(e) =>
                        handlePriceChange(item?.id, parseInt(e.target.value))
                      }
                    />
                    <Btn {...{ title: 'ازالة', func: () => addMat(item?.id) }} />
                  </Box>
                </Box>
                :
                <Box key={key} sx={Style?.tag} onClick={() => addMat(item?.id)}>
                  <Typography fontSize={25} color='primary.text'> {item?.material_name} </Typography>
                </Box>
            ))}
          </Box>
        </Box>

      </Box>

    </Box>
  )
}

export default AddSubConstruction