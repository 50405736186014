import { Box, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Footer = ({id}) => {
    const nav = useNavigate()
  return (
    <Box sx={{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        position:'fixed',
        width:'100%',
        bottom:0,
        backgroundColor:'#e7e7e7'
    }}>
            <Button onClick={() => nav(`/view-space/${id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" > مساحة المشروع  </Button>
            <Button onClick={() => nav(`/all-equipment/${id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" >  تجهيزات الموقع  </Button>
            <Button onClick={() => nav(`/construction-all/${id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" >  اعمال العظم  </Button>
            <Button onClick={() => nav(`/architecture-all/${id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" >  اعمال معمارية </Button>
            <Button  onClick={() => nav(`/plumbing-all/${id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" > اعمال السباكة  </Button>
            <Button  onClick={() => nav(`/electric-all/${id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" >  اعمال الكهرباء </Button>
            <Button onClick={() => nav(`/employees/${id}`)}  sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" > الكادر الإداري  </Button>
            <Button onClick={() => nav(`/specialized-works/${id}/ `)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" >  الاعمال التخصصية </Button>
            <Button onClick={() => nav(`/all-budget/${id}/ `)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" > بيانات موازنة الشركة </Button>
            <Button onClick={() => nav(`/all-bulletin/${id}`)} sx={{color:'primary.text',borderColor:'primary.red',m:1}} variant="outlined" >  نشرة اسعار المواد و الاجهزة  </Button>
    </Box>
  )
}

export default Footer