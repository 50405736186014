import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Vals } from '../../../config/Vals';
import { DataTable, Footer, TableHeader } from '../../../components'
import { Style } from './Style'
import { NumC, useLocalState } from '../../../config/functions';
import Btn from '../../../components/Btn/Btn';

const ViewSpecializedWorks = ({ nav }) => {

    const [data, setData] = useState([])
    const [Totals, setTotals] = useState([])
    const { id } = useParams()
    const [savedToken, _] = useLocalState("token")
    var totals = []
    const [space, setSpace] = useState(0)

    const getSpace = () => {


        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + savedToken);

        var raw = "";

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${Vals?.API_KEY}get_all_space_statement/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => setSpace(result?.data?.project_stats[0]?.total_space_with_coefficient))
            .catch(error => console.log('error', error));

    }

    useEffect(() => {
        getSpace()
    }, [])

    const getData = async () => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + savedToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        await fetch(`${Vals?.API_KEY}get_all_specialized_works/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                var a = 0
                result?.data?.statements?.map((item) => {
                    item?.sub_statements?.map((v) => {
                        a += v.considered_cost_price
                    })
                    setTotals(prevTotals => [...prevTotals, { total: a, id: item?.id }]);
                })
                setData(result?.data)
            })
            .catch(error => console.log('error', error))
    }

    useEffect(() => {
        getData()
    }, [])

    const types = [
        { title: 'اعمال عظم', value: 'construction', url: `/construction-all/${id}` },
        { title: 'اعمال سباكة', value: 'plumbing', url: `/plumbing-all/${id}` },
        { title: 'اعمال معمارية', value: 'architecture', url: `/architecture-all/${id}` },
        { title: 'اعمال كهرباء', value: 'electrical', url: `/electric-all/${id}` },
    ]

    const TableLoop = () => (
        data?.statements?.map((v, key) => {

            return (
                <Box sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                    flexDirection: 'column',
                    mt: 2,
                }}>
                    {v?.main?.length > 0 && (
                        <Box sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            alignContent: 'center',
                            backgroundColor: '#fabf8f',
                            flexDirection: 'column',
                            mt: 2,
                            width: '100%'
                        }}>
                            <Typography color='black' fontSize={25} m={2}>  {types?.find((i) => i.value == v?.type)?.title} </Typography>

                        </Box>
                    )}


                    {

                        v?.main?.map((i, k) => {
                            var total_a = 0

                            return (
                                <table style={Style?.Table}>
                                    <thead>
                                        {/*  */}
                                        <tr style={{
                                            borderColor: 'black',
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            color: 'black',
                                            textAlign: 'center',
                                            padding: 10,
                                            backgroundColor: '#b6dde8'
                                        }}>
                                            <th style={Style?.Col}>{k + 1}</th>
                                            <th style={Style?.Col}>{i?.main_statement_name}</th>
                                            <th style={Style?.Col}>الوحدة</th>
                                            <th style={Style?.Col}>الكمية</th>
                                            <th style={Style?.Col}>سعر وحدة التكلفة</th>
                                            <th style={Style?.Col}>التكلفة المدروسة</th>
                                            <th style={Style?.Col}><Btn {...{ title: 'اضافة', func: () => nav(`specialized-works-add-sub/${id}/${i?.id}`) }} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {i?.sub_statements?.map((item, key) => {
                                            total_a += item?.considered_cost_price
                                            return (
                                                <>

                                                    <tr style={{
                                                        borderColor: 'black',
                                                        borderStyle: 'solid',
                                                        borderWidth: 1,
                                                        color: 'black',
                                                        textAlign: 'center',
                                                        padding: 10,
                                                        backgroundColor: '#ccc0d9'
                                                    }}>
                                                        <th style={Style?.Col}>{k + 1} . {key + 1}</th>
                                                        <th style={Style?.Col}>{item?.sub_statement_name}</th>
                                                        <th style={Style?.Col}>{item?.unit}</th>
                                                        <th style={Style?.Col}>{item?.quantity}</th>
                                                        <th style={Style?.Col}>{item?.cost_unit_price}</th>
                                                        <th style={Style?.Col}>{NumC((item?.considered_cost_price).toFixed(2))}</th>
                                                        <th style={Style?.Col}> </th>
                                                    </tr>


                                                </>

                                            )
                                        })}


                                        <tr style={{ backgroundColor: '#bbbbbb' }}>
                                            <th style={Style?.ColTotal} colSpan={5}> الإجمالي </th>
                                            <th style={Style?.Col}> {NumC((total_a).toFixed(2))} </th>
                                            <th style={Style?.Col}>  </th>
                                        </tr>
                                        <tr style={{ backgroundColor: '#bbbbbb' }}>
                                            <th style={Style?.ColTotal} colSpan={5}> تكلفة على المتر	  </th>
                                            <th style={Style?.Col}> {NumC((isNaN(total_a * space) ? 0 : total_a * space).toFixed(2))}  </th>
                                            <th style={Style?.Col}>  </th>
                                        </tr>
                                        <tr style={{ backgroundColor: '#bbbbbb' }}>
                                            <th style={Style?.ColTotal} colSpan={4}> إدارة عامة +كادر إداري			  </th>
                                            <th style={Style?.ColTotal} colSpan={1}> {data?.space_project?.manage_cost}%	  </th>
                                            <th style={Style?.Col} colSpan={1}> {NumC((total_a * (data?.space_project?.manage_cost / 100)).toFixed(2))} </th>
                                            <th style={Style?.Col}>  </th>
                                        </tr>

                                        <tr style={{ backgroundColor: '#bbbbbb' }}>
                                            <th style={Style?.ColTotal} colSpan={4}> مربح			  </th>
                                            <th style={Style?.ColTotal} colSpan={1}> {data?.space_project?.profit}%   </th>
                                            <th style={Style?.Col} colSpan={1}> {NumC((total_a * (data?.space_project?.profit / 100)).toFixed(2))} </th>
                                            <th style={Style?.Col}>  </th>
                                        </tr>
                                        <tr style={{ backgroundColor: '#bbbbbb' }}>
                                            <th style={Style?.ColTotal} colSpan={5}> إجمالي مع الإدارة والمربح		  </th>
                                            <th style={Style?.Col} colSpan={1}> {
                                                NumC(((total_a * (data?.space_project?.manage_cost)) +
                                                    (total_a * (data?.space_project?.profit)) +
                                                    total_a).toFixed(2))
                                            } </th>
                                            <th style={Style?.Col}>  </th>
                                        </tr>

                                    </tbody>
                                </table>
                            )
                        })}
                </Box>
            )
        })
    )

    return (
        <Box sx={Style?.Container}>
            <TableHeader {...{ title: ' الأعمال التخصصية', buttonTitle: 'اضافة', page: () => nav(`/specialized-works-add-main/${id}`) }} />
            <TableLoop />

            <table style={{ marginTop: 20, width: '100%', backgroundColor: '#fabf8f' }}>
                <tbody>
                    <tr>
                        <th style={Style?.ColTotal} colSpan={5}> اجمالى أعمال التخصصية	  </th>
                        <th style={Style?.Col}> {NumC((data?.considered_cost_price))} </th>
                    </tr>
                    <tr>
                        <th style={Style?.ColTotal} colSpan={5}> تكلفة أعمال التخصصية على المتر	  </th>
                        <th style={Style?.Col}> {NumC((isNaN(data?.considered_cost_price * space) ? 0 : data?.considered_cost_price * space).toFixed(2))}  </th>
                    </tr>
                    <tr>
                        <th style={Style?.ColTotal} colSpan={4}> إدارة عامة +كادر إداري			  </th>
                        <th style={Style?.ColTotal} colSpan={1}> {data?.space_project?.manage_cost}%  </th>
                        <th style={Style?.Col} colSpan={1}> {NumC((data?.considered_cost_price * (data?.space_project?.manage_cost / 100)).toFixed(2))} </th>
                    </tr>

                    <tr>
                        <th style={Style?.ColTotal} colSpan={4}> مربح			  </th>
                        <th style={Style?.ColTotal} colSpan={1}>{data?.space_project?.profit}%   </th>
                        <th style={Style?.Col} colSpan={1}> {NumC((data?.considered_cost_price * (data?.space_project?.profit / 100)).toFixed(2))} </th>
                    </tr>
                    <tr>
                        <th style={Style?.ColTotal} colSpan={5}> إجمالي مع الإدارة والمربح		  </th>
                        <th style={Style?.Col} colSpan={1}> {
                            NumC(((data?.considered_cost_price * (data?.space_project?.manage_cost)) +
                                (data?.considered_cost_price * (data?.space_project?.profit)) +
                                data?.considered_cost_price).toFixed(2))
                        } </th>
                    </tr>
                </tbody>
            </table>

            <Box sx={{ height: 200 }} />
            <Footer {...{ id: id }} />

        </Box>
    )
}

export default ViewSpecializedWorks