import { Button, CardMedia, InputBase, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Style } from './Style'
import LoginIcon from '@mui/icons-material/Login';
import { useState } from 'react';
import { useData } from '../../config/Provider';
import { useLocalState } from '../../config/functions';
import { Vals } from '../../config/Vals';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Login = () => {
    const { logged, setlogged, token, setToken } = useData()
    const [username, setusername] = useState('mamdouh@gmai.com')
    const [password, setpassword] = useState('123456789')
    const [Islogged, setIslogged] = useLocalState("Islogged", false);
    const [savedToken, setSavedToken] = useLocalState("token", null);
    const nav = useNavigate()
    const Submit = async () => {
        var formdata = new FormData();
        formdata.append("email", username);
        formdata.append("password", password);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${Vals?.API_KEY}login`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.status === true) {
                    setSavedToken(result?.data?.token)
                    setToken(result?.data?.token)
                    setIslogged(true)
                    setlogged(true)
                    nav('/home/')
                } else {
                    setlogged(false)
                    const MySwal = withReactContent(Swal)

                    MySwal.fire({
                        title: <strong>خطأ !</strong>,
                        html: <i>البريد  الالكتروني أو  كلمة السر خاطئة</i>,
                        icon: 'error'
                    })
                }

            })
            .catch(error => console.log('error', error));
    }

    return (

        <Box sx={Style?.Container}>

            <Box sx={Style?.FormContainer}>
                <img src={'./logo.jpeg'} width={180} />
                <Box sx={Style?.Form} mt={5}>
                    <InputBase value={username} onChange={(e) => setusername(e.target.value)} sx={Style?.Input} placeholder="اسم المستخدم" type="username" variant="outlined" autoComplete='off' />
                    <InputBase value={password} onChange={(e) => setpassword(e.target.value)} sx={Style?.Input} placeholder="كلمة المرور" type="Password" variant="outlined" autoComplete='off' />
                </Box>

                <Box mt={2}>
                    <Button onClick={Submit} sx={Style?.Button} variant="contained" > تسجيل الدخول </Button>
                </Box>

            </Box>

        </Box>
    )
}

export default Login