import { Box, Table, TableBody, td, TableContainer, TableHead, tr, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Vals } from '../../../config/Vals';
import { DataTable, Footer, TableHeader, TextInput } from '../../../components'
import { Style } from './Style'
import { useLocalState } from '../../../config/functions';
import Btn from '../../../components/Btn/Btn';

const ViewBulletin = ({nav}) => {
    
    const [data, setData] = useState([])
    const {id} = useParams()
    const [ savedToken , _ ] = useLocalState("token" )

    const page = () => {
        nav(`/bulletin-add-main/${id}`)
    }
 
    const getData =  async ()  => {
        var myHeaders = new Headers()
        myHeaders.append("Authorization", "Bearer "+savedToken)
        var requestOptions = {
        method: 'GET',
          headers:  myHeaders
        };
        await fetch(`${Vals?.API_KEY}get_all_price_bulletin/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => setData(result?.data))
        .catch(error => console.log('error', error))
    }
      
    useEffect(() => {
        getData()
    }, [])

    const Header = [
        { title : 'الرقم' } ,
        { title : ' البيان ' } ,
        { title : ' وحدة ' } ,
        { title : ' وحدة المادة ' } ,
        { title : ' السعر لكل وحدة ' } ,
        { title : ' الكمية ' } ,
        { title : ' سعر التكلفة ' } ,
        { title : '  ملاحظات  ' } ,
        { title : ' اضافة ' } ,
    ]

    const TableLoop = () => ( 
        <table style={Style?.Table}>

            <tr >
                <th colSpan={4} style={Style?.Col}> <Typography variant='h5'>
                نشرة أسعار مواد أعمال معمارية
                    </Typography>  </th>
                <th colSpan={5} style={Style?.Col}> 
                <Typography variant='h5'>
                      تاريخ التحديث 
                  </Typography>
                  </th>
            </tr>

            <tr>
                <th colSpan={4} style={Style?.Col}> 
                <Typography variant='h5'>
                 بيانات المشتريات 
                 </Typography>
                 </th>
                <th colSpan={5} style={Style?.Col}> 
                <Typography variant='h5'>
                 بيانات المكتب الفني 
                 </Typography>
                 </th>
            </tr>
      
        {data?.map( ( v , k) => (
          <>
                    <tr  style={{ 
                    borderColor:'black' ,
                    borderStyle:'solid',
                    borderWidth:1, 
                    transition:'0.2s' , 
                    cursor : 'pointer' , 
                    '&:hover' : { 
                        backgroundColor : 'primary.main'
                    } ,
                    "& td": { 
                        borderColor:'black'
                    },
                    backgroundColor:'#fabf8f'
                }}> 
                    <td   style={Style?.Col} > * </td> 
                    <td  style={Style?.Col} > البند </td> 
                    <td  style={Style?.Col} > الوحدة </td> 
                    <td  style={Style?.Col} > بيان الوحدة </td> 
                    <td  style={Style?.Col} >  الكمية </td> 
                    <td  style={Style?.Col}>  السعر </td> 
                    <td  style={Style?.Col}>  سعر التكلفة </td> 
                    <td  style={Style?.Col}  >  ملاحظة </td> 
                
                </tr>  
            <tr key={v.id} style={{ 
                    borderColor:'black' ,
                    borderStyle:'solid',
                    borderWidth:1, 
                    transition:'0.2s' , 
                    cursor : 'pointer' , 
                    '&:hover' : { backgroundColor : 'primary.main'} ,
                    "& td": { borderColor:'black'},
                    backgroundColor:'#b6dde8'
                }}> 
                
                <td  style={Style?.Col} > {k+1} <br />  <Btn {...{title:'اضافة',func:()=>nav(`/bulletin-add-sub/${id}/${v.id}`)}}/>   </td> 
                <td  style={Style?.Col} > {v.main_statement_name} </td> 
                <td  style={Style?.Col} > {v.unit} </td> 
                <td   style={Style?.Col}>  {v.statement_unit} </td> 
                <td   style={Style?.Col}>  {v.cost_price} </td> 
                <td   style={Style?.Col}>  {v.quantity} </td> 
                <td   style={Style?.Col}>  {v.quantity * v.cost_price} </td> 
                <td  style={Style?.Col} >  {v.note} </td> 
                 
            </tr>

      
            {v.sub_statements?.map((item,key) => (
                <tr key={key}  style={{ 
                    borderColor:'black' ,
                    borderStyle:'solid',
                    borderWidth:1, 
                    transition:'0.2s' , 
                    cursor : 'pointer' , 
                    '&:hover' : { 
                        backgroundColor : 'primary.main'
                    } ,
                    "& td": { 
                        borderColor:'black'
                    },
                    backgroundColor:'#ccc0d9'
                }}> 
                    <td   style={Style?.Col}> {k+1}.{key+1} </td> 
                    <td  style={Style?.Col} > {item?.sub_statement_name} </td> 
                    <td  style={Style?.Col} > {item?.statement_unit} </td> 
                    <td  style={Style?.Col} > {item.unit} </td> 
                    <td  style={Style?.Col} >  {item.quantity} </td> 
                    <td  style={Style?.Col} >  {item.price} </td> 
                    <td  style={Style?.Col} >  {item.price * item.quantity} </td> 
                    <td  style={Style?.Col} colSpan={2} >  {item.note} </td> 
                </tr>   
            ))}
  
          </>

        ))}
        </table>
    )

    return (
        <Box style={Style?.Container}>
            <TableHeader {...{ title:'نشرة اسعار المواد و الاجهزة' , buttonTitle:'اضافة' , page : page } } />
            <TableLoop />
            <Footer {...{id:id}}/>
        </Box>
    )
}

export default ViewBulletin