import { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {   AddProject, Login, Projects , AllArchitecture 
     ,AddSubArchitecture ,AddMainArchitecture, AllConstruction, AddMainConstruction, AddSubConstruction
     , AddEmployee, Employees, AddSpace, ViewSpace, AddMainPlumbing
     , AllPlumbing, AddSubPlumbing, AllElectric, AddMainElectric, AddSubElectric, ViewSpecializedWorks, AddMainSpecializedWorks, AddSubSpecializedWorks, AddSubBudget, ViewBudget, AddBudget, ViewBulletin, AddBulletin, AddSubBulletin, ViewEquipment, AddSubEquipment, AddEquipment  } from '../pages/'
import { useLocalState } from "./functions";
import { Vals } from "./Vals";

const Router = () => {

    const nav = useNavigate() 

    const [ Islogged ] = useLocalState("Islogged");

    const ProtectedRoutes = () => {
        const location = useLocation();
        return Islogged ? (<Outlet />) : (<Navigate to="/login" replace state={{ from: location }} />);
    };

    const PAGES = [
        // home
        {path :'/Home',element:<Projects {...{nav}} />},
        {path :'/',element:<Projects {...{nav}} />},
        
        // projects 
        {path :'/projects',element:<Projects {...{nav}} />},
        {path :'/add-project',element:<AddProject {...{nav}} />},
    
        // architecture
        {path :'/architecture-all/:id',element:<AllArchitecture {...{nav}} />},
        {path :'/architecture-add-main/:id',element:<AddMainArchitecture {...{nav}} />},
        {path :'/architecture-add-sub/:id/:mid',element:<AddSubArchitecture {...{nav}} />},
        
        // construction
        {path :'/construction-all/:id',element:<AllConstruction {...{nav}} />},
        {path :'/construction-add-sub/:id/:mid',element:<AddSubConstruction {...{nav}} />},
        {path :'/construction-add-main/:id',element:<AddMainConstruction {...{nav}} />},
        
        // employees
        {path :'/add-employee/:id',element:<AddEmployee {...{nav}} />},
        {path :'/employees/:id',element:<Employees {...{nav}} />},

        // space
        {path :'/add-space/:id',element:<AddSpace {...{nav}} />},
        {path :'/view-space/:id',element:<ViewSpace {...{nav}} />},
        
        // plumbing
        {path :'/plumbing-all/:id',element:<AllPlumbing {...{nav}} />},
        {path :'/plumbing-add-sub/:id/:mid',element:<AddSubPlumbing {...{nav}} />},
        {path :'/plumbing-add-main/:id',element:<AddMainPlumbing {...{nav}} />},

        // electric
        {path :'/electric-all/:id',element:<AllElectric {...{nav}} />},
        {path :'/electric-add-sub/:id/:mid',element:<AddSubElectric {...{nav}} />},
        {path :'/electric-add-main/:id',element:<AddMainElectric {...{nav}} />},

        // specialized-works
        {path :'/specialized-works/:id',element:<ViewSpecializedWorks {...{nav}} />},
        {path :'/specialized-works-add-sub/:id/:mid',element:<AddSubSpecializedWorks {...{nav}} />},
        {path :'/specialized-works-add-main/:id',element:<AddMainSpecializedWorks {...{nav}} />},

        // budget
        {path :'/all-budget/:id',element:<ViewBudget {...{nav}} />},
        {path :'/budget-add-sub/:id/:mid',element:<AddSubBudget {...{nav}} />},
        {path :'/budget-add-main/:id',element:<AddBudget {...{nav}} />},

        // Bulletin
        {path :'/all-bulletin/:id',element:<ViewBulletin {...{nav}} />},
        {path :'/bulletin-add-sub/:id/:mid',element:<AddSubBulletin {...{nav}} />},
        {path :'/bulletin-add-main/:id',element:<AddBulletin {...{nav}} />},

        // Equipment
        {path :'/all-equipment/:id',element:<ViewEquipment {...{nav}} />},
        {path :'/equipment-add-sub/:id/:mid',element:<AddSubEquipment {...{nav}} />},
        {path :'/equipment-add-main/:id',element:<AddEquipment {...{nav}} />},

    ]

    if (!Islogged) return <Login />

    if (Islogged) {

        return   (
            <Routes >   
                <Route path="/login" element={<Login  {...{nav}}/>} />
                <Route element={<ProtectedRoutes />}>
                    {PAGES?.map((item,key) => (
                        <Route path={item?.path} key={key} element={item?.element}   />
                    ))}
                </Route>
            </Routes>
        )  

    }
  
};

export default Router;