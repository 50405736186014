
export const Style = {
    
    Container : {
        paddingRight : 5 ,
        paddingLeft : 5 ,
        paddingTop : 2
    } ,
 
    Row : { 
        borderColor:'red' , 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }

    } ,

    SearchInput : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderColor:'red',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        color : 'white' ,
        width:'100%' ,
    }

}

